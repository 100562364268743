import { FETCH_PRACTICE_PRODUCTS } from "../actions/types";

export default (state = null, action) => {
    switch (action.type) {
        
        case FETCH_PRACTICE_PRODUCTS:
            return {
                ...state,
                details: action.payload,
            };
        default:
            return state;
    }
};
