import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import {
  showProfile,
  closeAll,
  showAppointments,
  showMessages,
  clearCss,
  removeClearCss,
  burgerAnimation,
} from "../redux/actions/sideMenuActions";
import { resultsHistoryPageHandler, pendingScansPageHandler } from "../redux/actions/tablePaginationActions";
import { setPreviousPage } from "../redux/actions/fetchResultHistoryActions";
import { bindActionCreators } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHistory, faPlusCircle, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { faListAlt, faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { logout } from "../redux/actions/securityActions";
import { withTranslation } from 'react-i18next'
import Submenu from './Submenu'
import {ThemeContext} from '../context/theme-context';
import MenuAccordion from './MenuAccordion'
import ThemeTogglerButton from './theme-toggler-button';
const initialState = {
  accordion: '',

}

class Sidemenu extends Component {

  constructor(){
    super();
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      isHover: false,
      accordion: initialState.accordion,
    };
    // this.isHover = true;
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
    }
  }

  adminDashboardClick = () => {
    this.props.closeAll();
    this.props.clearCss();
    this.props.setPreviousPage(1);

    // Close the sidemenu with the burger button animation
    this.props.burgerAnimation();
    this.props.parentCallback(false);
  };

  resultHistoryClick = () => {};

  logout() {
    this.props.resultsHistoryPageHandler(1);
    this.props.pendingScansPageHandler(1);
    this.props.logout();
  }

  showMenuLinks = () => {
    var eq = false
    var la = false
    var ca = false
    var cache = {
      'eq':'',
      'la':'',
      'ca':''
    }
    if(localStorage.getItem('practiceDocumentation')){cache = JSON.parse(localStorage.getItem('practiceDocumentation'));}
    eq = cache['eq'];la = cache['la'];ca = cache['ca']
    
    const menuLinks = (
      <ThemeContext.Consumer>
      {({theme, darkMode}) => (
      <ul className="sidemenu-ul" style={{backgroundColor: theme.background, color:theme.textcolor}}>
        <div id="borderRight" style={{backgroundColor: theme.blue, color:theme.blue}}></div>
        <NavLink /* Create Scan */
          className={`sidemenu-nav  ${this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""}`}
          to="/scan"
          // data-umami-event='Sidemenu: to Create Scan'
          activeClassName="activeMI"
          exact
          style={{backgroundColor: theme.background, color:theme.textcolor}}
        >
          <li className={darkMode ? "sidemenu-li_dark" : "sidemenu-li" } id="sidemenu-home" onClick={this.adminDashboardClick} >
            <FontAwesomeIcon icon={faPlusCircle} size="1x" className="fontawesome-sidemenu" />
            {this.props.t('createScan')}
          </li>
        </NavLink>
        <div id="borderRight"></div>

        <NavLink /* Pending Scans */
         className={`sidemenu-nav  ${this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""}`}
          activeClassName="activeMI"
          // data-umami-event='Sidemenu: to Pending Scans'
          to="/pending-scans"
          exact
        >
          <li className={darkMode ? "sidemenu-li_dark" : "sidemenu-li" }  id="sidemenu-home" onClick={this.adminDashboardClick}>
            <FontAwesomeIcon icon={faListAlt} size="1x" className="fontawesome-sidemenu" />
            {this.props.t('pendingScans')}
          </li>
        </NavLink>

        <div id="borderRight"></div>
        <NavLink /* Results history */
          className={`sidemenu-nav  ${this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""}`}
          activeClassName="activeMI"
          to="/results-history"
          // data-umami-event='Sidemenu: to Results History'
          exact
        >
          <li className={darkMode ? "sidemenu-li_dark" : "sidemenu-li" } id="sidemenu-home" onClick={this.adminDashboardClick}>
            <FontAwesomeIcon icon={faHistory} size="1x" className="fontawesome-sidemenu" />
            {this.props.t('resultsHistory')}
          </li>
        </NavLink>

        {/* TODO UMAMI */}
        <div id="borderRight"></div>
          <div className="support-menu-header" style={{backgroundColor:theme.background}}>
            <MenuAccordion
              title={this.props.t('support')}
              children={<Submenu parentCallback={this.props.parentCallback} />}
              linksToShow={[eq, ca]}
            >
            </MenuAccordion>
          </div>          
        
        <NavLink /* Contact us */
          className={`sidemenu-nav  ${this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""}`}
          activeClassName="activeMI"
          to="/contact"
          // data-umami-event='Sidemenu: to Contact Page'
          exact
        >
          <li className={darkMode ? "sidemenu-li_dark" : "sidemenu-li" }  id="sidemenu-home" onClick={this.adminDashboardClick}>
            <FontAwesomeIcon icon={faEnvelope} size="1x" className="fontawesome-sidemenu" />
            {this.props.t('contactUs')}   
          </li>
        </NavLink>

        <div /* Logout */
          className={`sidemenu-nav  ${
            this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""
          } sidemenu-top-links`}
          // data-umami-event='Sidemenu: Logout'
          onClick={this.logout.bind(this)}
        >
          <li className={darkMode ? "sidemenu-li_dark" : "sidemenu-li" }  id="sidemenu-home" onClick={this.adminDashboardClick}>
            <FontAwesomeIcon icon={faSignOutAlt} size="1x" className="fontawesome-sidemenu" />
            {this.props.t('logout')}
          </li>
        </div>

        <div /* DarkMode */
          className={`sidemenu-nav  ${
            this.props.sideMenuClear.addClearCSS ? "clearCSS" : ""
          } sidemenu-top-links`}
        >
          <li className={darkMode ? "sidemenu-li_dark" : "sidemenu-li" }  id="sidemenu-home" onClick={this.adminDashboardClick}>
            <><ThemeTogglerButton /></>
          </li>
        </div>
      </ul>
      )}
      </ThemeContext.Consumer>
    
    );
    return menuLinks; 
  };

  render() {
    return (
      <ThemeContext.Consumer>
      {({theme}) => (
      <div ref={this.wrapperRef} className="container" id="sidemenu-col" style={{backgroundColor: theme.background, color:theme.textcolor}}>
        <div className="row dark_sidemenu-row-wrapper">
          <div className="col">{this.showMenuLinks()}</div>
        </div>
      </div>
       )}
      </ThemeContext.Consumer>
    )
  }
}

const mapStateToProps = state => ({
  security: state.security,
  sideMenu: state.sideMenu,
  sideMenuClear: state.sideMenuClear,
  practiceDocumentation: state.practiceDocumentation

});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      showProfile,
      showMessages,
      showAppointments,
      closeAll,
      burgerAnimation,
      clearCss,
      removeClearCss,
      setPreviousPage,
      logout,
      resultsHistoryPageHandler,
      pendingScansPageHandler,
    },
    dispatch
  );
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Sidemenu));

