import { NEW_USER } from "../../redux/actions/types";

const initialState = {
    newUserEmail: ""
};
  
  
export default function foo(state = initialState, action) {
    switch (action.type) {
        case NEW_USER:
        return {
            ...state,
            newUserEmail: action.newUserEmail
        };

        default:
        return state;
    }
}
  