import { backInstance } from "../../config";
import history from "../../history";
import { EDIT_WORKLIST, GET_ERRORS, GET_SCAN_COMMENT_DATA, FETCH_PRACTICE_PRODUCTS} from "./types";

const sortPracticeData = (product) => { // takes api data and sorts into a dict -> which is used to to create the expandable areas and buttons
  let apiData;
  let credtTypeproductAuth = {} // used to create an easily queryable dict of auth products
  let largestCreditType = 0

  if (product){
    apiData = product;
    for (const key in apiData) {
      credtTypeproductAuth[apiData[key]['label']] = apiData[key]['product_authorisations']
      if(largestCreditType < apiData[key]['product_authorisations'].length){
        largestCreditType = apiData[key]['product_authorisations'].length // finding the largest string...to be used for styling
      }
    }
  }
  else{
    console.log("No product authorisations found")
  } 
  return [credtTypeproductAuth, largestCreditType]
};

export const createScan = createScanRequest => async dispatch => {
  try {
    if (createScanRequest.description.trim() === "") {
      for (let i = 0; i < createScanRequest.animal_id.length; i++) {
        await backInstance.post(`/scan`, {
          owner_name: createScanRequest.owner_name,
          animal_id: createScanRequest.animal_id[i].animalName,
          description: createScanRequest.animal_id[i].comment,
          scan_product: {
            $ref: "/scanproduct/" + createScanRequest.scan_product,
          },
        });
      }
      history.push("/pending-scans");
    } else {
      await backInstance
        .post(`/batch`, {
          description: createScanRequest.description,
        })
        .then(async result => {
          for (let i = 0; i < createScanRequest.animal_id.length; i++) {
            await backInstance.post(`/scan`, {
              owner_name: createScanRequest.owner_name,
              animal_id: createScanRequest.animal_id[i].animalName,
              description: createScanRequest.animal_id[i].comment,
              parent_batch: {
              $ref: result.data.$uri,
              },
              scan_product: {
                $ref: "/scanproduct/" + createScanRequest.scan_product,
              },
            });
          }
          history.push("/pending-scans");
        });
    }
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.data,
    });
  }
};

export const editScan = (editScanRequest, id) => async dispatch => {
  try {
    const res = await backInstance.patch(`/scan/${id}`, {
      owner_name: editScanRequest.owner_name,
      animal_id: editScanRequest.animal_id,
      description: editScanRequest.description,
      scan_product: {
        $ref: "/scanproduct/" + editScanRequest.scan_product,
      },
    });
    history.push("/pending-scans");
    dispatch({
      type: EDIT_WORKLIST,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const editScanComment = (editScanRequest, id) => async dispatch => {
  try {
      const res = await backInstance.patch(`/scan/${id}`, {
        comment: editScanRequest.comment,
      });
      dispatch({
        type: EDIT_WORKLIST,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      }); 
  }  
}

export const getScanComment = (id) => async dispatch => {
  try {
      const res = await backInstance.get(`/scan/${id}`,{
        headers: {
          'Cache-Control': 'no-cache'
        }})
      dispatch({
        type: GET_SCAN_COMMENT_DATA,
        payload: res.data.comment,
      });
    } catch (error) {
  }
}

export const fetchpracticeproducts = async () => {
  try {
    const res = await backInstance.get(`/practice/products`)
    const [credtTypeproductAuth, largestCreditType] = sortPracticeData(res.data)

    localStorage.setItem('credtTypeproductAuth', JSON.stringify(credtTypeproductAuth))
    localStorage.setItem('largestCreditType', JSON.stringify(largestCreditType))
    // localStorage.setItem('practiceProducts', JSON.stringify(res.data))
  } catch (error) {
    console.log('error: ', error)
  }
}

export const fetchScanProducts = async () => {
  try {
    let scanProductDict = {}

    const res = await backInstance.get(`/scanproduct`)
    for (let i = 0; i < res.data.length; i++) {
      scanProductDict[res.data[i]['$uri']] = res.data[i]['description']
    }
    localStorage.setItem('scanProductDict',  JSON.stringify(scanProductDict))

  } catch (error) {
    console.log(error)
  }
}


export const fetchSpecies = async () => {
  try {
    let speciesDict = {}

    const res = await backInstance.get(`/species`)
    for (let i = 0; i < res.data.length; i++) {
      speciesDict[res.data[i]['$uri']] = res.data[i]['label']
    }
    localStorage.setItem('species', JSON.stringify(speciesDict))

  } catch (error) {
    console.log(error)
  }
}