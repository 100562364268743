import { useTranslation } from 'react-i18next'
import Modal from './Modal'

const ConfirmationModal = ({ isShow, closeModal, onConfirm, children }) => {
  const { t } = useTranslation()
  return (
    <Modal isShow={isShow} closeModal={closeModal}>
      <div className='confirmation-modal'>
        <h3 className='confirmation-modal__title'>{t('confirmChangePassword')}</h3>
        <p className='confirmation-modal__message'>{t('confirmChangePasswordMessage')}</p>
        {children}
        <div className='confirmation-modal__btns'>
          <button className='confirmation-modal__btn confirmation-modal__btn--cancel' onClick={closeModal}>
            {t('cancel')}
          </button>
          <button className='confirmation-modal__btn confirmation-modal__btn--confirm' onClick={onConfirm}>
            {t('confirm')}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmationModal
