import { SHOW_PROFILE, SHOW_APPOINTMENTS, SHOW_MESSAGES, CLOSE_ALL, BURGER_ANIMATION } from "../../redux/actions/types";

const INITIAL_STATE = {
  showProfile: false,
  showMessages: false,
  showAppointments: false,
  burgerAnimation: false,
};

export default function foo(state = INITIAL_STATE, action){
  switch (action.type) {
    case SHOW_PROFILE:
      return { ...state, showProfile: true, showMessages: false, showAppointments: false };
    case SHOW_MESSAGES:
      return { ...state, showProfile: false, showMessages: true, showAppointments: false };
    case SHOW_APPOINTMENTS:
      return { ...state, showProfile: false, showMessages: false, showAppointments: true };
    case BURGER_ANIMATION:
      return { ...state, burgerAnimation: true };
    case CLOSE_ALL:
      return { ...state, showProfile: false, showMessages: false, showAppointments: false, burgerAnimation: false };
    default:
      return state;
  }
};
