import { CLEAR_CSS, REMOVE_CLEAR_CSS } from "../../redux/actions/types";

const INITIAL_STATE = {
    addClearCSS: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CLEAR_CSS:
            return { ...state, addClearCSS: true }
        case REMOVE_CLEAR_CSS:
            return { ...state, addClearCSS: false }
        default:
            return state;
    }
};