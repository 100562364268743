

import { backInstance} from '../../config'
import history from '../../history'
import { SET_CURRENT_USER, NEW_USER, USER_ALERT, GET_ERRORS, LOGIN_START } from './types'
import { CognitoIdentityProvider } from '@aws-sdk/client-cognito-identity-provider'
import hmacSHA256 from 'crypto-js/hmac-sha256'
import Base64 from 'crypto-js/enc-base64'
import {fetchScanProducts, fetchSpecies, fetchpracticeproducts} from './scanActions'
import { fetchPracticeDocumentation } from "./fetchPracticeDocumentation";
import worker_script from "../../worker.js";
import {version} from '../../config.js'

const { REACT_APP_CLIENT_ID, REACT_APP_COGNITO_REGION, REACT_APP_COGNITO_APP_SECRET_KEY } = process.env;

const CLIENT_ID = REACT_APP_CLIENT_ID
const COGNITO_REGION = REACT_APP_COGNITO_REGION
const COGNITO_APP_SECRET_KEY = REACT_APP_COGNITO_APP_SECRET_KEY

const hashSecret = (clientSecret, username, clientId) => {
  const hash = hmacSHA256(username + clientId, clientSecret)
  const hashInBase64 = Base64.stringify(hash)
  return hashInBase64
}

const getCognitoAccessToken = async (username, password) => {

  const params = {
    AuthFlow: 'USER_PASSWORD_AUTH',
    ClientId: CLIENT_ID,
    AuthParameters: {
      PASSWORD: password,
      USERNAME: username,
      SECRET_HASH: hashSecret(COGNITO_APP_SECRET_KEY, username, CLIENT_ID),
    }, 
  }
  const provider = new CognitoIdentityProvider({ region: COGNITO_REGION })

  try {
    const res = await provider.initiateAuth(params)
    return res.AuthenticationResult.AccessToken
    } catch (e) {
  }
}

export const login = (LoginRequest, t) => async dispatch => {
  const accessToken = await getCognitoAccessToken(LoginRequest.username, LoginRequest.password)
  try {
    dispatch({
      type: LOGIN_START,
    })
    if (!accessToken) throw Error  
    localStorage.setItem('AccessToken', accessToken)
    localStorage.setItem('version', version)
    localStorage.setItem('refreshwhen', new Date(new Date().getTime() + 1*30*60*1000))

    // only get data not in storage already..we will periodcally update storage values while user is logged in using web workers
    if(!localStorage.getItem('scanProductDict')){await fetchScanProducts();}     // do api calls on login and store values for use later in app
    if(!localStorage.getItem('practiceDocumentation')){await fetchPracticeDocumentation();}  
    if(!localStorage.getItem('credtTypeproductAuth')){await fetchpracticeproducts();}  
    
    console.log("Updating Cache data at ", localStorage.getItem('refreshwhen'));

    try{
      const worker = new Worker(worker_script);
      
      worker.onmessage = ev => {
        const refreshwhen = localStorage.getItem('refreshwhen')
        let refreshwhenDate = new Date(refreshwhen)
        if(refreshwhenDate <= new Date()){ // every 30 minutes check that 30 mins has passed since last cache update - also checked on refresh or new screen opening
          fetchpracticeproducts();
          fetchPracticeDocumentation();
          fetchScanProducts();
          localStorage.setItem('refreshwhen', new Date(new Date().getTime() + 1*30*60*1000))
          console.log("cache updated")
        }
      }
      worker.postMessage("Data Fetch started");
    } catch (error){console.log(error)}


    const user = [`${LoginRequest.username}:${LoginRequest.password}`]
    dispatch({
      type: USER_ALERT,
      message: t('loggedInSuccessfully'),
      alertType: 'Success',
    })
    dispatch({
      type: SET_CURRENT_USER,
      payload: user,
      tokenExp: 'Never',
      validToken: true,
    })
    history.push('/pending-scans')
  } catch (error) {
    await dispatch({
      type: GET_ERRORS,
      payload:'errors',
    })
    dispatch({
      type: USER_ALERT,
      message: t('invalidUsernameOrPassword'),
      alertType: 'Warning',
    })
  }
}


export const changePassword = (currPassword, newPassword, formik, isGlobalLogout, t) => async dispatch => {
  const provider = new CognitoIdentityProvider({
    region: COGNITO_REGION,
    credentials: {
      accessKeyId: CLIENT_ID,
      secretAccessKey: COGNITO_APP_SECRET_KEY,
    },
  })
  try {
    await provider.changePassword({
      AccessToken: localStorage.getItem('AccessToken'),
      PreviousPassword: currPassword,
      ProposedPassword: newPassword,
    })

    if (isGlobalLogout) {
      provider
        .globalSignOut({
          AccessToken: localStorage.getItem('AccessToken'),
        })
        .then(() => {
          localStorage.removeItem('AccessToken')
          localStorage.removeItem('email')
          localStorage.removeItem('rememberMe')
          localStorage.removeItem('state')
          localStorage.removeItem('practiceDocumentation') 
          localStorage.removeItem('credtTypeproductAuth') 
          localStorage.removeItem('largestCreditType') 
        
          dispatch({
            type: SET_CURRENT_USER,
            payload: null,
            tokenExp: null,
          })
          history.push('/')
        })
    }
    dispatch({
      type: USER_ALERT,
      message: t('passwordHasBeenChanged'),
      alertType: 'Success',
    })
    formik.resetForm()
  } catch (err) {
    if (err?.message?.includes('password')) {
      formik.setFieldError('currentPassword', 'Invalid password')
    } else {
      dispatch({
        type: USER_ALERT,
        message: err.message,
        alertType: 'Warning',
      })
    }
  }
}

export const logout = () => dispatch => {
  localStorage.removeItem('AccessToken')
  localStorage.removeItem('email')
  localStorage.removeItem('rememberMe')
  localStorage.removeItem('state')
  localStorage.removeItem('practiceDocumentation') 
  localStorage.removeItem('credtTypeproductAuth') 
  localStorage.removeItem('largestCreditType') 
  dispatch({
    type: SET_CURRENT_USER,
    payload: null,
    tokenExp: null,
  })
}

export const register = RegisterUserRequest => async dispatch => {
  const res = await backInstance.post('/auth/users', RegisterUserRequest)
  dispatch({
    type: NEW_USER,
    payload: res,
    newUserEmail: RegisterUserRequest.email,
  })
  history.push('/VerifyEmail')
}

export const passwordReset = PasswordResetRequest => async dispatch => {
  try {
    await backInstance.post('/auth/users/forgotPassword', PasswordResetRequest)
  } catch (err) {}
}