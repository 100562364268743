import { DELETE_WORKLIST } from "../actions/types";

export default (state = null, action) => {
    switch (action.type) {
        case DELETE_WORKLIST:
            return {
                ...state,
                worklistDeleted: action,
            };
        default:
            return state;
    }
};
