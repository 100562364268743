import { EDIT_WORKLIST } from "../actions/types";

export default (state = null, action) => {
    switch (action.type) {
        case EDIT_WORKLIST:
            return {
                ...state,
                worklistEdit: action,
            };
        default:
            return state;
    }
};

