import { FETCH_RH_DETAILS } from "../actions/types";

export default (state = null, action) => {
    switch (action.type) {
        case FETCH_RH_DETAILS:
            return {
                ...state,
                details: action.payload,
            };
        default:
            return state;
    }
};
