import { backInstance } from '../../config'
import { GET_ACTIVE_DEVICE_ID, GET_ACTIVE_DEVICE_DATA, GET_PRACTICE_DATA, GET_DISTRIBUTOR, SET_SCAN_COMMENT, GET_PRACTICE_ID } from '../actions/types'

export const getActiveDevice = () => async dispatch => {
  try {
    const res = await backInstance.get('/device/mine')
    dispatch({
      type: GET_ACTIVE_DEVICE_ID,
      payload: res.data.device,
    })
  } catch (error) {
    // window.alert("Get active device API Error");
  }
}

export const getDistributor = () => async dispatch => {
  try {
    const res = await backInstance.get(`/distributor/mine`)
    dispatch({
      type: GET_DISTRIBUTOR,
      payload: res.data,
    })
  } catch (error) {
    // window.alert('Get practice Data API Error')
  }
}


export const getActiveDeviceData = deviceId => async dispatch => {
  try {
    const res = await backInstance.get(`/device/${deviceId}`)
    dispatch({
      type: GET_ACTIVE_DEVICE_DATA,
      payload: res.data,
    })
  } catch (error) {
    // window.alert("Get active device Data API Error");
  }
}

export const getPracticeData = () => async dispatch => {
  try {
    const res = await backInstance.get(`/practice/mine/logo`, { responseType: 'blob' })
    dispatch({
      type: GET_PRACTICE_DATA,
      payload: URL.createObjectURL(res.data),
    })
  } catch (error) {
    // window.alert('Get practice Data API Error')
  }
}

export const imageUploadMessage = (message, type) => dispatch => {
  dispatch({
    type: 'USER_ALERT',
    message: message,
    alertType: type,
  })
}