import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import {
  closeAll,
  clearCss,
  removeClearCss,
  burgerAnimation
} from "../redux/actions/sideMenuActions";

import { setPreviousPage } from "../redux/actions/fetchResultHistoryActions";
import { bindActionCreators } from "redux";
import { withTranslation } from 'react-i18next'
import { faHorse, faQuestionCircle, faBacteria, faDog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ThemeContext } from '../context/theme-context';

class Submenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
    };
  };

  componentDidMount() {
  }

  adminDashboardClick = () => {
    this.props.closeAll();
    this.props.clearCss();
    this.props.setPreviousPage(1);
    this.props.burgerAnimation();
    this.props.parentCallback(false);
  };

  resultHistoryClick = () => { };
  showMenuLinks = () => {
    var eq = false
    var la = false
    var ca = false
    var cache = {
      'eq':'',
      'la':'',
      'ca':''
    }
    if(localStorage.getItem('practiceDocumentation')){cache = JSON.parse(localStorage.getItem('practiceDocumentation'));}
    eq = cache['eq'];la = cache['la'];ca = cache['ca']
    const menuLinks = (
      <ThemeContext.Consumer>
        {({ theme, darkMode }) => (
          <ul className="submenu-ul" style={{ backgroundColor: theme.background, color: theme.textcolor }}>
            <NavLink /* Support */
              activeClassName="activeSubMI"
              to="/support"
              // data-umami-event='Submenu: to FAQ page'
              exact
            >
              <li className="submenu-li" style={{ color: theme.textcolor }} id="submenu-home" onClick={this.adminDashboardClick}>
                < FontAwesomeIcon icon={faQuestionCircle} size="1x" className="fontawesome-submenu" />
                {this.props.t('FAQ')}
              </li>
            </NavLink>

            <NavLink /* FEC PREP */
              activeClassName="activeSubMI"
              to="/fec-scan-help"
              exact
              // data-umami-event='Submenu: to FEC help page'
              hidden={!eq}
            >
              <li className="submenu-li" style={{ color: theme.textcolor }} id="submenu-home" onClick={this.adminDashboardClick}>
                <FontAwesomeIcon icon={faHorse} size="1x" className="fontawesome-submenu" />
                {window.innerWidth > 350 ? this.props.t('FECPreperation') : this.props.t('FECPrep')}
              </li>
            </NavLink>

            <NavLink /* Fluke PREP */
              activeClassName="activeSubMI"
              to="/fluke-scan-help"
              exact
              hidden={!la}
              // data-umami-event='Submenu: to Fluke help page'
            >
              <li className="submenu-li" style={{ color: theme.textcolor }} id="submenu-home" onClick={this.adminDashboardClick}>
                <FontAwesomeIcon icon={faBacteria} size="1x" className="fontawesome-submenu" />
                {window.innerWidth > 350 ? this.props.t('FlukePreperation') : this.props.t('FlukePrep')}
              </li>
            </NavLink>

            <NavLink /* Pet Fluke PREP */
              activeClassName="activeSubMI"
              to="/pet-scan-help"
              exact
              // data-umami-event='Submenu: to Pet help page'
              hidden={!ca}
            >
              <li className="submenu-li" style={{ color: theme.textcolor }} id="submenu-home" onClick={this.adminDashboardClick}>
              <FontAwesomeIcon icon={faDog} size="1x" className="fontawesome-submenu" />
                {window.innerWidth > 350 ? this.props.t('CaPreperation'): this.props.t('CaPrep')}
              </li>
            </NavLink>
          </ul>
        )}
      </ThemeContext.Consumer>
    );
    return menuLinks;
  };

  render() {
    return (
      <div className="container" id="submenu-col">
        <div className="row submenu-row-wrapper">
          <div className="col">{this.showMenuLinks()}</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  security: state.security,
  Submenu: state.Submenu,
  practiceDocumentation: state.practiceDocumentation
  //   sideMenuClear: state.sideMenuClear,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      closeAll,
      clearCss,
      removeClearCss,
      burgerAnimation,
      setPreviousPage,
    },
    dispatch
  );
};

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Submenu));
