import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { Link, withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import SupportMaterial from '../../../components/SupportMaterial'
import ThumbWithText from '../../../components/ThumbWithText'
import { ThemeContext } from '../../../context/theme-context'

const CaScanHelp = () => {
  const { t } = useTranslation()

  var eq = false
  var la = false
  var ca = false
  if(localStorage.getItem('practiceDocumentation')){var cache = JSON.parse(localStorage.getItem('practiceDocumentation'));}
  eq = cache['eq'];la = cache['la'];ca = cache['ca']
  
  return (
    <ThemeContext.Consumer>
    {({ theme, darkMode }) => (
    <div className='dashboard-container cow-bg' style={{ height: 'auto', background: theme.foreground, color: theme.textcolor}}>
      <div className='white-background about-us-container mx-auto pb-4 pr-5 '
        style={{
          background: '#f2f2f2c0',
          padding: '0px 25px 25px',
          background: theme.foreground, 
          color: theme.textcolor
        }}
      >
        <h3 className='' style={{ paddingTop: '120px' }}>
          {t('helpWithCaScan')}
        </h3>
        <div className='' style={{ paddingTop: '20px' }}>
          <p>
            {t('thisIsAhelpPageCA')}
            <br /> <br />
            {t('pleaseFollowBelow')}
            <br /> <br />
            {t('ovacyteSupportIsHereToHelp')}{' '}
            <Link to='/contact'>
              <u  style={{ color: '#009FEE' }}>{t('contactUs')}.</u>
            </Link>  
          </p>
        </div>

        {/* <div
          className='row'
          style={{
            color: '#212529',
            fontSize: '1rem',
            fontWeight: '400',
            margin: 'auto',
          }}
        > */}

        <div
          className='row'
          style={{
            color: '#212529',
            fontSize: '1rem',
            fontWeight: '400',
            margin: 'auto',
            color: theme.textcolor
          }}
        >
          <div className='col-12 col-md-10 mr-5 pl-0'>
            <h4
              className='mb-4'
              style={{
                paddingTop: '20px',
                paddingBottom: '20px',
                borderBottom: '1px solid #4472C4',
                fontSize: '20px',
              }}
            >

            </h4>

            <div className='ThumbWithText' style={{ paddingTop: '12px' }}>
              <ThumbWithText explainText='TestVid.mp4' url='CA_Sample_Prep.mp4' thumbnail='CA_SAMPLE_PRED_COVER.png'>
              </ThumbWithText>
            <br></br>
            {/* <p><b>Tutorial Video </b></p>
            To <b>start </b> a Fec scan follow these steps */}
            </div>  

            <div className='fecHowToList' >
            <ol>
                <li>{t('fillTheCutterEndOfTheInsertWithFaeces')}</li>
                <li>{t('pushTheInsertIntoTheTube')}</li>
                <li>{t('takeFlotationFluidUsingTheSyringe')}</li>
                <li>{t('InjectAllTheFFIntoTheTube')} </li>
                <li>{t('GentlyMashTheSolutionUntilMixedThoroughly')}</li>
                <li>{t('UsingTheSyringeExtractTheFiltrate')}</li>
                <li>{t('WithFingerOnTheSyringeTopPullBackThePlunder')} </li>
                <li>{t('DepressThePlungerWithTheSyringePointingUpwardsUntilTheFluidReachesTheTop')}</li>
                <li>{t('InjectTheFluidIntoTheCassetteAndPlaceOnTheAnalyser')}</li>
            </ol>
            </div>
                      
            <div>
              <br></br>
              <br></br>
            </div>

            <h4
              className='mb-4 help-topics-header'
              style={{
                paddingTop: '20px',
                paddingBottom: '20px',
                borderBottom: '1px solid #4472C4',
                fontSize: '20px',
              }}
            >
            {t('browseHelpTopics')} 
            </h4>
            
            <div className='helpLinks'> 
              <Link className={`helpLink${darkMode ? '_dark' : ''}`} to='/fluke-scan-help'>
              <div style={{color: theme.textcolor}}>{t('helpwithFlukeScan')}</div>
              </Link>
              <br></br>
              <Link className={`helpLink${darkMode ? '_dark' : ''}`}  to='/fec-scan-help' hidden={!ca}>
                <div style={{color: theme.textcolor}}>{t('helpwithFECScan')} </div>
              </Link>
              <br></br>
              <Link className={`helpLink${darkMode ? '_dark' : ''}`}  to='/support'>
              <div style={{color: theme.textcolor}}>{t('frequentlyAskedQuestions')}</div>
              </Link>
              <br></br>
            </div>
          
          </div>
            {/* <SupportMaterial /> */}
        </div>
      </div>
    </div>
    )}
    </ThemeContext.Consumer>
  )
  
}

const mapStateToProps = state => ({
  security: state.security,
  practiceDocumentation: state.practiceDocumentation,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
  }, dispatch)
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(CaScanHelp)
