import { FETCH_LATEST_UPDATE } from '../actions/types'

const initialState = {
  data: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LATEST_UPDATE:
      return {
        ...state,
        data: action.payload,
      }
    default:
      return state
  }
}
