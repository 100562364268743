import { FETCH_WORKLIST_START, FETCH_WORKLIST_SUCCESS, FETCH_WORKLIST_FAILURE } from "../actions/types";

const initialState = {
    worklist: null,
    count: 0,
    status: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_WORKLIST_START:
            return {
                ...state,
                isLoading: true,
            };
        case FETCH_WORKLIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                worklist: action,
                count: action.count,
                status: 400
            };
        case FETCH_WORKLIST_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error,
                status: action.status
            };
        default:
            return state;
    }
};
