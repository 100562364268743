import { CLEAR_ALERT } from "./types";

/*
 ** userAlertActions.js
 ** Controls actions around the popup alert used in the application.
 */

export const clearUserAlert = () => {
  return {
    type: CLEAR_ALERT,
  };
};
