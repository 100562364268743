import { USER_ALERT, CLEAR_ALERT } from "../../redux/actions/types";

/*
 ** userAlertReducer.js
 ** Returns user alert state based on action
 */

const INITIAL_STATE = {
  showAlert: false,
  message: "",
  alertType: "",
};

const userAlertReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER_ALERT:
      return { ...state, showAlert: true, message: action.message, alertType: action.alertType };
    case CLEAR_ALERT:
      return { ...state, showAlert: false, message: "", alertType: "" };
    default:
      return state;
  }
};
export default userAlertReducer;
