import React, { Component } from 'react';
import { withTranslation } from 'react-i18next'

class Button extends Component {
    render() {
        const {
            styleClass,
            handleClick,
            value,
            data_umami_event
        } = this.props;

    return(
        <button data-umami-event={this.props.t(data_umami_event)} className={`btn ${styleClass}`} onClick={handleClick} >
            {this.props.t(value)}
        </button>
        );
    }
}

export default withTranslation()(Button);