import React, { Component } from "react";
import { connect } from "react-redux";

class Dashboard extends Component {
    render() {
        return ( 
            <div className="dashboard-container cow-bg" id="dashboard-container"></div>
        );
    }
}

const mapStateToProps = state => ({
    security: state.security
});
  
export default connect(mapStateToProps, null)(Dashboard);