import { FETCH_RESULTS_START, FETCH_RESULTS_SUCCESS, FETCH_RESULTS_FAILURE } from "../actions/types";

const initialState = {
    history: null,
    count: 0,
    status: null
};

export default function foo(state = initialState, action){
    switch (action.type) {
        case FETCH_RESULTS_START:
            return {
                ...state,
                isLoading: true,
            };
        case FETCH_RESULTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                history: action,
                count: action.count,
                status:400
            };
        case FETCH_RESULTS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error,
                status: action.status
            };
        default:
            return state;
    }
};
