import {RH_SELECTED} from "../actions/types";

const INITIAL_STATE = {
  scan_id: null,
  batch_id: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RH_SELECTED:
      return {...state, scan_id: action.scan_id, batch_id: action.batch_id};
    default:
      return state;
  }
};
