import {FETCH_BATCH_DETAILS, FETCH_BATCH_ERROR, FETCH_BATCH_REPORT} from "../actions/types";

const initialState = {
  batchReport: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BATCH_DETAILS:
      return {
        ...state,
        batch_details: action.payload,
      };
    case FETCH_BATCH_ERROR:
      return {
        ...state,
        batch_details: null,
      };
    case FETCH_BATCH_REPORT:
      return {
        ...state,
        batchReport: action.payload,
      };
    default:
      return state;
  }
};
