import { SET_RH_PREVIOUS_PAGE, RESET_RH_PREVIOUS_PAGE } from "../../redux/actions/types";

const INITIAL_STATE = {
    previousPage: 1,

};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_RH_PREVIOUS_PAGE:
            return { ...state, previousPage: action.payload }
        case RESET_RH_PREVIOUS_PAGE:
            return { ...state, previousPage: 1}
        default:
            return state;
    }
};