import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { changePassword } from '../redux/actions/securityActions'
import * as yup from 'yup'
import { useDispatch } from 'react-redux'
import { useState } from 'react'
import ConfirmationModal from './ConfirmationModal'
import {ThemeContext} from '../context/theme-context';

const validationSchema = yup.object({
  currentPassword: yup.string().required('requiredField'),
  newPassword: yup
    .string()
    .required('requiredField')
    .matches(
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%^&*()_+={}[\]|\\:;"'<>,.?/~`])[A-Za-z\d@$!%^&*()_+={}[\]|\\:;"'<>,.?/~`]{12,}$/,
      'newPasswordMatches'
    ),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'passwordsMustMatch')
    .required('requiredField'),
  isGlobalLogout: yup.boolean(),
})

const ResetPassword = () => {
  const dispatch = useDispatch()
  const [isShow, setIsShow] = useState(false)

  const { t } = useTranslation()

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      isGlobalLogout: false,
    },
    validationSchema,
    validateOnBlur: false,
    onSubmit: values => {
      dispatch(changePassword(values.currentPassword, values.newPassword, formik, values.isGlobalLogout, t))
    },
  })

  const closeModal = () => {
    setIsShow(false)
  }

  const openModal = () => {
    //  Check if there is any empty field, if so, validate the form
    if (Object.values(formik.values).some(value => typeof value === 'string' && value.trim() === '')) {
      formik.validateForm()
      return
    }

    // if there is no empty field, check if the form is valid and show the modal
    if (formik.isValid) setIsShow(true)
  }

  const onConfirm = () => {
    formik.handleSubmit()
    closeModal()
  }

  return (
    <ThemeContext.Consumer>
    {({theme, darkMode}) => (
    <>
      <ConfirmationModal isShow={isShow} closeModal={closeModal} onConfirm={onConfirm}>
        <div className='confirmation-modal__checkbox'>
          <input type='checkbox' {...formik.getFieldProps('isGlobalLogout')} />
          <p>{t('signOutFromAllDevices')}</p>
        </div>
      </ConfirmationModal>
      <form onSubmit={formik.handleSubmit}>
        <FormGroup >
          <Label label='currentPassword'/>
          <Input
            type='password'
            placeholder={t('currentPassword')}
            {...formik.getFieldProps('currentPassword')}
            hasError={!!(formik.touched.currentPassword && formik.errors.currentPassword)}
          />
          {formik.errors.currentPassword && (
            <p className='rp-error-text'>{t(formik.errors.currentPassword)}</p>
          )}
        </FormGroup>
        <FormGroup>
          <Label label='newPassword' />
          <Input
            type='password'
            placeholder={t('newPassword')}
            {...formik.getFieldProps('newPassword')}
            hasError={!!(formik.touched.newPassword && formik.errors.newPassword)}
          />
          {formik.errors.newPassword && <p className='rp-error-text'>{t(formik.errors.newPassword)}</p>}
        </FormGroup>
        <FormGroup>
          <Label label='confirmNewPassword' />
          <Input
            type='password'
            placeholder={t('confirmNewPassword')}
            {...formik.getFieldProps('confirmNewPassword')}
            hasError={!!(formik.touched.confirmNewPassword && formik.errors.confirmNewPassword)}
          />
          {formik.errors.confirmNewPassword && (
            <p className='rp-error-text'>{t(formik.errors.confirmNewPassword)}</p>
          )}
        </FormGroup>
        <button data-umami-event='FAQ: Reset password' type='button' className='rp-button' onClick={openModal}>
          {t('resetPassword')}
        </button>
      </form>

    </>
        )}
  </ThemeContext.Consumer>
  ) 
}

export default ResetPassword

const Label = ({ label }) => {
  const { t } = useTranslation()
  return (
    <label className='rp-label' id={label}>
      {t(label)}
    </label>
  )
}

const Input = ({ hasError, ...restProps }) => {
  return (
  <ThemeContext.Consumer>
    {({theme, darkMode}) => (
      <input style={{backgroundColor: theme.inputcolor}} className={` ${hasError ? 'rp-error' : 'rp-normal'} rp-input`} {...restProps} />
    )}
  </ThemeContext.Consumer>)
}

const FormGroup = ({ children }) => {
  return <div className='d-flex flex-column mb-3 position-relative'>{children}</div>
}
