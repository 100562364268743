import React, { useRef } from 'react'
import Chevron from './Chevron'
import '../css/Accordion.css'
import { useTranslation } from 'react-i18next'
import { ThemeContext } from '../context/theme-context'

function Accordion(props) {
  const content = useRef(null)
  const { t } = useTranslation()
    return (
      <ThemeContext.Consumer>
      {({theme, darkMode}) => (
      <div className={`accordion__section`}>
          <button 
            className={`accordion${darkMode ? '_dark' : ''} ${props.state}`} onClick={props.dispatch}
          >
          <p className='accordion__title'>{t(props.title)}</p>
          <Chevron
              className={`${props.state === 'active' ? 'accordion__icon rotate' : 'accordion__icon'}`}
              width={10}
              fill={theme.textcolor}
          />
        </button>
        <div
          ref={content}
          style={{
            maxHeight:`${props.state === 'active' ? `${content.current.scrollHeight}px` : '0px'}`,
            backgroundColor: theme.background, color: theme.textcolor
          }}
          className='accordion__content'
        >
        <div className='accordion__text'>{props.children}</div>
        </div>
      </div>
      )}
      </ThemeContext.Consumer>
    )
  }
  
export default Accordion
