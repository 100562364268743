import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { withRouter } from 'react-router-dom'
import aboutImg from '../../assets/about.jpg'
import { Trans, withTranslation } from 'react-i18next'

export class About extends Component {
  render() {
    return (
      <div>
        <div className='white-background-customers about-us-container mx-auto pb-4'>
          <div className='table-search-wrapper'>
            <h3 className='header-worklist'>{this.props.t('aboutUs')}</h3>
          </div>
          <div
            className='row rh-table-wrapper'
            style={{ color: '#212529', fontSize: '1rem', fontWeight: '400' }}
          >
            <div className='col-xl-12 col-12'>
              <p>{this.props.t('aboutP1')}</p>
            </div>
            <div className='col-xl-12 col-12 p-0 row'>
              <div className='col-md-4 ml-3 col-12'>
                <img alt='about' style={{ width: '100%', objectFit: 'contain' }} src={aboutImg} />
              </div>
              <div className='col-md-7 ml-4 col-12'>
                <p>
                  {this.props.t('aboutP2a')}
                  <br />
                  <br />
                  {this.props.t('aboutP2b')}
                </p>
              </div>
            </div>
            <div className='col-xl-12 col-12 mt-4'>
              <p>
                <Trans i18nKey='aboutP3a' />
                <br />
                <br />
                {this.props.t('aboutP3b')}
                <br />
                <br />
                {this.props.t('aboutP3c')}
                <br />
                <br />
                {this.props.t('aboutP3d')}
              </p>

              <h5>{this.props.t('aboutP4')}</h5>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  security: state.security,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch)
}

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(withTranslation()(About))
