import { FETCH_PRACTICE_DATA_INDEX_REDUCER } from '../actions/types'

const initialState = {
  id: 0,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PRACTICE_DATA_INDEX_REDUCER:
      return {
        ...state,
        id: action.payload,
      }
    default:
      return state
  }
}
