import { HIDE_MESSAGE } from "../../redux/actions/types";

const initialState = {
    showMessage: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case HIDE_MESSAGE:
            return {
                ...state,
                showMessage: action.payload,
            };

        default:
            return state;
    }
}
