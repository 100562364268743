import React, { Component } from "react";
import { withTranslation } from 'react-i18next'

class Input extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  }
  
  render() {
    const { className, placeholder, type, name, inputRef, value, pattern } = this.props;
    return (
      <input
        className={className}
        ref={inputRef}
        placeholder={this.props.t(placeholder)}
        name={name}
        type={type}
        value={value}
        pattern={pattern}
        onChange={this.handleChange}
      />
    );
  }
}

export default withTranslation()(Input);
