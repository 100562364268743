import { GET_ACTIVE_DEVICE_ID, GET_ACTIVE_DEVICE_DATA, GET_PRACTICE_DATA, GET_DISTRIBUTOR } from '../../redux/actions/types'

const initialState = {
  myDeviceId: null,
  myDeviceData: null,
  myPractice: null,
}

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ACTIVE_DEVICE_ID:
      return {
        ...state,
        myDeviceId: action.payload,
      }
    case GET_ACTIVE_DEVICE_DATA:
      return {
        ...state,
        myDeviceData: action.payload,
      }
    case GET_PRACTICE_DATA:
      return {
        ...state,
        myPractice: action.payload,
      }
    case GET_DISTRIBUTOR:
      return {
        ...state,
        myDistributor: action.payload,
      }
    default:
      return state
  }
}
