import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import DataTable, { createTheme } from 'react-data-table-component';
import { withRouter } from "react-router-dom";
import { withTranslation } from 'react-i18next'
import Dropdown from 'react-bootstrap/Dropdown'
import {ThemeContext} from '../../context/theme-context';

import {
  fetchWorklist,
  editWorklist,
  deleteWorklist,
} from "../../redux/actions/fetchWorkListActions";

import { fetchBatchDetails } from "../../redux/actions/fetchResultHistoryActions";
import { pendingScansPageHandler } from "../../redux/actions/tablePaginationActions";
import FilterDataTable from "../../components/FilterDataTable";

createTheme('solarized', {
  text: {
    primary: '#ffffff',
    secondary: '#ffffff',
  },
  background: {
    default: '#232322',
  },
  context: {
    background: '#ffffff',
    text: '#ffffff',
  },
  divider: {
    default: '#000001',
  },
  button: {
    default:'#CDCDCD',
    focus: '#232322',
    hover: '#363636',
    disabled: '#363636',
  },
  action: {
    button: '#ffffff',
    hover: '#ffffff',
    disabled: '#ffffff',
  },
}, 'solarized');


export class Worklist extends Component {
  constructor() {
    super();
    this.state = {
      selectedRowID: null,
    }
  }

  componentDidMount() {
    this.scan_product_dict = JSON.parse(localStorage.getItem('scanProductDict'))
  }

  returnSpecies_fromScanProduct(speciesUri) {
    if (this.scan_product_dict) {
      return this.scan_product_dict[speciesUri]
    }
    return ''
  }

  editSelect = async row => {
    this.setState({
      selectedRowID: row.scan_id,
    });
  };

  editRowHtml(){
    return (
    <div className="edit-row">
    <button
      onClick={() => this.props.deleteWorklist(this.state.selectedRowID)}
      type="button"
      data-umami-event='Pending Scans: Delete Scan'
      data-umami-event-ps-delete-id={this.state.selectedRowID}
      className={
        this.state.selectedRowID != null
          ? "rh-register-button rh-delete-selected"
          : "rh-register-button"
      }
    >
      {this.props.t('delete')}
    </button>
    <button
      onClick={() => this.props.editWorklist(this.state.selectedRowID)}
      type="button"
      data-umami-event='Pending Scans: Edit Scan'
      data-umami-event-ps-edit-id={this.state.selectedRowID}
      className={
        this.state.selectedRowID != null
          ? "rh-register-button rh-detailed-selected"
          : "rh-register-button"
      }
    >
      {this.props.t('edit')}
    </button>
  </div>)
  }

  renderWorklist() {
    if (this.props.worklist !== null) {
      return this.props.worklist.worklist.map(history => {
        return {
          date: new Intl.DateTimeFormat('en-GB', {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }).format(history.created.$date),
          animal_owner: history.owner_name,
          animal_id: history.animal_id,
          verified: history.verified.toString(),
          scan_id: history.$uri.replace(/[^\d.]/g, ""),
          scan_product: history.scan_product === null || history.scan_product === undefined ? 'none' : history.scan_product.$ref,
          batch_description: history.batch_description === null | history.batch_description === undefined ? '-' : history.batch_description,
          batch_id:
            history.parent_batch === null
              ? "none"
              : history.parent_batch.$ref.replace(/[^\d.]/g, ""),
        };
      });
    }
  }

  getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return width
  }

  render() {
    let conditionalRowStyles;
    if (this.state.selectedRowID !== undefined) {
      conditionalRowStyles = [
        {
          when: row => row.scan_id === this.state.selectedRowID,
          style: {
            color: ThemeContext.Consumer._currentValue.theme.blue,
            "&:hover": {
              cursor: "pointer",
            },
          },
        },
      ];
    }

    const customStyles = {
      title: {
        style: {
          fontColor: ThemeContext.Consumer._currentValue.theme.textcolor,
          fontWeight: '900',
          backgroundColor:ThemeContext.Consumer._currentValue.theme.backgroundColor ,
        }
      },
      rows: {
        style: {
          color:ThemeContext.Consumer._currentValue.theme.textcolor,
          fontFamily: 'sans-serif',
          fontSize: '14px' ,
          minHeight: '38px',
          backgroundColor: ThemeContext.Consumer._currentValue.theme.background,
        }
      },
      headCells: {
        style: {
          color:ThemeContext.Consumer._currentValue.theme.textcolor,
          fontFamily: 'sans-serif',
          fontSize: '14px' ,
          minHeight: '38px',
          backgroundColor: ThemeContext.Consumer._currentValue.theme.foreground,
        },
      },
      cells: {
        style: {
          fontSize: '15px',
          backgroundColor: ThemeContext.Consumer._currentValue.theme.background,
        },
      },
    }
    
    let width = 0
    width = this.getWindowDimensions()
    let max_col_width = "310px"
    if(width < 1500 & width > 1200){
      max_col_width = "210px"
    }
    if(width < 1350 & width > 1200){
      max_col_width = "185px"
    }
    if(width < 1250 & width > 1199){
      max_col_width = "180px"
    }

    const columns = [
      {
        name: this.props.t('date'),
        selector: "date",
        filterTerm:'created',
        grow: 1,
        maxWidth: max_col_width,
        type:'date',
      },
      {
        name: this.props.t('ownerReference'),
        selector: "animal_owner",
        filterTerm:'owner_name',
        grow: 1,
        maxWidth: max_col_width,
        center: true,
        type:'str',
      },
      {
        name: this.props.t('animalName'),
        selector: "animal_id",
        filterTerm:'animal_id',
        grow: 1,
        center: true,
        hide: 'sm',
        maxWidth: max_col_width,
        type:'str',
      },
      {
        name: this.props.t('species'),
        selector: "scan_product",
        filterTerm:'scan_product',
        grow: 1,
        hide: 'md',
        maxWidth: max_col_width,
        center: true,
        cell: row => this.returnSpecies_fromScanProduct(row.scan_product),
        type:'dropdown',
      },
      {
        name: this.props.t('scanID'),
        selector: "scan_id",
        filterTerm:'$uri',
        grow: 1,
        maxWidth: max_col_width,
        center: true,
        type:'int',
      },
      {
        name: this.props.t('batchName'),
        selector: "batch_description",
        filterTerm:'batch_description',
        grow: 1,
        maxWidth: max_col_width,
        center: true,
        type:'NotInUse',
      },
    ];


    return (
      <ThemeContext.Consumer>
      {({ theme }) => (
        <div className="white-background-customers" >
          <div className="table-search-wrapper">
            <h3 className= {"header-worklist"} style={{backgroundColor: theme.foreground,color: theme.textcolor}}>{this.props.t('pendingScans')}</h3>
          </div>
          <FilterDataTable 
            columns={columns} 
            conditionalRowStyles={conditionalRowStyles} 
            fetchTableData={this.props.fetchWorklist} 
            pageHandler={this.props.pendingScansPageHandler} 
            extraButtons={this.editRowHtml()}
            onRowClicked={this.editSelect}
            customStyles={customStyles}
            renderList={this.renderWorklist()}
            workList={this.props.worklist}
            status={this.props.status}
            isLoading={this.props.isLoading}
            paginationPage={this.props.currentPagePendingScans}
          >
          </FilterDataTable>
        </div>
      )}
    </ThemeContext.Consumer>
    )
  }
}

const mapStateToProps = state => ({
  security: state.security,
  worklist: state.worklist.worklist,
  count: state.worklist.count,
  currentPagePendingScans: state.pendingScansTablePage.page,
  status:state.worklist.status,
  isLoading:state.worklist.isLoading
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchWorklist,
      editWorklist,
      deleteWorklist,
      pendingScansPageHandler,
    },
    dispatch
  );
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(withTranslation()(Worklist));
