import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import Button from '../components/Button';

class VerifyEmail extends Component {
  
  render() {
      return(
        <div className="flex-row">
          <div className="verify-email-container" id="verify-email-container">
            <h4>{this.props.t('verifyYourEmail')}</h4>
            <div>{this.props.t('anEmailHasBeenSentTo')} {this.props.signup}. {this.props.t('withALinkToVerifyYourAccount')}. {this.props.t('ifThisIsNotYourEmailPlease')} <Link to="/Signup">{this.props.t('updateIt')}</Link></div>
              <FontAwesomeIcon icon={faEnvelope}  className="fontawesome-dash envelope-verify-email" />
            <div>{this.props.t('ifYouHaveNotReceivedAnEmailAfterAFewMinutesPleaseCheckYourSpamFolder')}</div>
              <Button value="resendEmail"  styleClass="btn-primary"  />
              <Button value="contactSupport"  styleClass="btn-inverse-primary btn-outline-primary" />          
           </div>
        </div>
      );
  }
}

const mapStateToProps = state => ({
  signup: state.newUserEmail
});

export default withTranslation()(connect(mapStateToProps, null)(VerifyEmail));