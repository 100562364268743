import { FETCH_PRACTICE_DOCUMENTATION } from "../actions/types";

export default (state = null, action) => {
    switch (action.type) {
        
        case FETCH_PRACTICE_DOCUMENTATION:
            return {
                ...state,
                details: action.payload,
            };
        default:
            return state;
    }
};
