import {SET_CURRENT_USER, GET_ERRORS, LOGIN_START} from "../../redux/actions/types";

const initialState = {
  validToken: false,
  user: {},
  errors: null,
};

const booleanActionPayload = (payload) => {
  if (payload) {
    return true;
  } else {
    return false;
  }
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN_START:
      return {
          ...state,
          isLoading: true,
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        validToken: booleanActionPayload(action.validToken),
        user: action.payload,
        tokenExp: action.tokenExp,
        isLoading: false,
      };
    case GET_ERRORS:
      return {
        ...state,
        errors: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
}
