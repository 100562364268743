import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { clearUserAlert } from "../redux/actions/userAlertActions";
import IconButton from "@material-ui/core/IconButton";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import { withTranslation } from 'react-i18next'

/*
 ** UserAlertModal.js
 ** User Alerting  modal with 3 color schemes. It dispayes the error message from the user alert property in redux.
 */

class UserAlertModal extends Component {
  // Different color schemes
  modalColors = () => {

    switch (this.props.userAlert.alertType) {
      case "Logout":
        return "user-alert-modal-logout";
      case "Warning":
        return "user-alert-modal-warning";
      case "Caution":
        return "user-alert-modal-caution";
      case "Success":
        return "user-alert-modal-success";
      default:
        return "user-alert-modal-success";
    }
  };

  autoHide = () => {
    setTimeout(() => {
      this.props.clearUserAlert();
    }, 1.5 * 1000);
  };

  refresh = () => {
    this.props.clearUserAlert();
    setTimeout(()=>{
      localStorage.clear()
      window.location.reload();
    }, 500);
  }

  noAutoHide = () => {

  };
  render() {
    return (
      <div>
        {this.props.userAlert.showAlert === true ? (
          <Modal
            id={this.modalColors()}
            show={this.props.userAlert.showAlert}
            onHide={this.props.userAlert.alertType==='Logout' ? this.noAutoHide : this.props.clearUserAlert}
            backdropClassName="user-alert-modal-backdrop"
            dialogClassName="user-alert-modal-dialog"
            onEntered={this.props.userAlert.alertType==='Logout' ? this.noAutoHide : this.autoHide}
          >
            <span>
              {this.props.userAlert.message}
              {this.props.userAlert.alertType==='Logout' ? <button onClick={this.refresh} className="btn-alert-logout">{this.props.t('refresh')}</button>:''}
              {this.props.userAlert.alertType!=='Logout' ?
              <IconButton
                color="secondary"
                aria-label="add an alarm"
                onClick={this.props.clearUserAlert}
              >
                <ClearOutlinedIcon />
              </IconButton>
              :''}
            </span>
          </Modal>
        ) : (
          <div></div>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  userAlert: state.userAlert,
});

export default connect(mapStateToProps, { clearUserAlert })(withTranslation()(UserAlertModal));
