import React, { Component } from 'react';
import ReactPlayer from 'react-player/lazy'
import '../css/ThumbWithText.css'

class ThumbWithText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  render() {
    const {  url, thumbnail } = this.props;

    return (
      <div className=''>
        {<div className=''>
        </div>}
        {
          <div className='videoColumn'>
            <ReactPlayer
              className='reactPlayer'
              url={url}
              playing={true}
              controls={true}
              light={thumbnail}
              width='100%'
              height='100%'
              loop={false}
            />
          </div>
        }
      </div>
    );
  }
}

export default ThumbWithText;
