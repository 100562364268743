import React, { useCallback, useEffect} from 'react'
import { connect } from 'react-redux'
import { 
  fetchBatchReport, 
  editBatchScanComment,
  fetchBatchDetails,
  getPracticeDataIndex, 
} from '../../redux/actions/fetchResultHistoryActions';
import generatePDF, { Margin } from "react-to-pdf";
import { useState } from 'react';
import { FaAlignLeft, FaAlignRight, FaAlignCenter } from "react-icons/fa";
import { getPracticeData } from '../../redux/actions/deviceSettingsActions'
import { bindActionCreators } from 'redux'
import { NavLink } from 'react-router-dom'
import { useTranslation, withTranslation } from 'react-i18next'
import { object } from 'yup'
import {ThemeContext} from '../../context/theme-context';
import { USER_ALERT } from '../../redux/actions/types'
import TextareaAutosize from 'react-textarea-autosize';

const emblemEnum = Object.freeze({
  left: 1,
  center: 2,
  right: 3
});


const practiceAndBatchDescription = (batchReport, t, fetchedPracticelabel) => {

  if (batchReport === null) {
    return <></>
  } else {
    const parasiteList = batchReport.batch_scans?.find(parasite => parasite.results?.length !== 0)
    const parasites =
      parasiteList &&
      parasiteList.results.map(parasite => ({
        description: parasite.description,
        display_name: parasite.display_name,
      }))

  return (
    
    <ThemeContext.Consumer>
    {({theme, darkMode}) => (
    <div className='mt-4 grid-container'>
        <div className='grid-item left-column'>
          <span className='left-col-text'>{t('batchID')}: </span>
          <span id='br-id'> {batchReport.batch_scans[0].owner_name}</span>
        </div>
        <div className='grid-item right-column'>
          <span className='left-col-text'>{t('testDate')}:</span>
          <span id='br-date'>
          {new Intl.DateTimeFormat('en-GB', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }).format(batchReport.batch_scans[0].created.$date)}
          </span>
        </div>
        <div className='grid-item left-column'>
          <span className='left-col-text'>{t('ownerReference')}: </span>
          <span> { batchReport? batchReport.batch_scans[0].owner_name:''}</span>
        </div>
        <div className='grid-item right-column'>
          <span className='left-col-text'>{t('practice')}:</span>
          <span> {fetchedPracticelabel ? fetchedPracticelabel : '' }</span>
        </div>
        <div className='grid-item left-column'>
          <span className='left-col-text'>{t('species')}: </span>
          <span
            style={{
              textTransform: 'capitalize',
            }}
          >
            {batchReport?.species ? batchReport.species.label?.replaceAll('_', ' ') : '-'}
          </span>
        </div>
        <div className='grid-item left-column'>
              <span className='left-col-text'>{t('description')}: </span>
              <span className='flex-wrapper-report_text'> {batchReport.description}</span>  
          </div>
    </div>
    )}
      </ThemeContext.Consumer>
  );
  
  }

  

}
const detailColumn = (batchReport, t, fetchedPracticelabel) => {

  if (batchReport === null) {
    return <></>
  } else {
    const parasiteList = batchReport.batch_scans?.find(parasite => parasite.results?.length !== 0)
    const parasites =
      parasiteList &&
      parasiteList.results.map(parasite => ({
        description: parasite.description,
        display_name: parasite.display_name,
      }))

    return (
      <ThemeContext.Consumer>
      {({theme, darkMode}) => (
      <div>
        {practiceAndBatchDescription(batchReport, t, fetchedPracticelabel.id.label)} 
        {/* Just add id.label here  */}
        <div className='table-search-wrapper'>
            <h4 className='report-header-text'>{t('batchReport')}</h4>
        </div>
              {/* This is the line */}
              <h4  
              className='mb-4'
              style={{
                paddingTop: '5px',
                paddingBottom: '15px',
                paddingRight: '10px',
                borderBottom: '1px solid #4472C4',
                fontSize: '20px',
              }}
              >
        </h4>
              
        <div className='mb-4'>
          <div className='col-12 table-overflow' >
            <table className='dr-table batch-report-table' id='batch-table' style={{color:theme.textcolor, backgroundColor: theme.tableColor1}}>
              <thead>
                <tr className='print_table'>
                  <th>{t('animalName')}</th>
                  <th >{t('scanID')}</th>
                  <th>{t('scanState')}</th>
                  {parasiteList?.results?.map(result => (
                    <th key={result.description}>{result.display_name}</th>
                  ))}
                </tr>
              </thead>
              <tbody className='dr-table-body'>
                {batchReport.batch_scans.map((scan, index) => {
                  const resultsList = []
                  if (scan.results.length === 0 && scan?.scan_state === 'created' && parasiteList) {
                    const emptyParatsites = Array(parasiteList?.results?.length).fill({
                      ...Object.keys(...parasiteList?.results).map(key => ({
                        [key]: '',
                      })),
                    })
                    resultsList.push(...emptyParatsites)
                  } else {
                    resultsList.push(...scan.results)
                  }
                  return (
                    <tr id={`results-table`} class={'results-table'} style={index%2?{backgroundColor: theme.tableColor2, color:theme.textcolor}:{backgroundColor: theme.background, color:theme.textcolor}} key={scan.uri}>
                      <td>{scan.animal_id}</td>
                      <td>{scan.uri.replace(/[^\d.]/g, '')}</td>
                      <td style={{ textTransform: 'capitalize' }}>
                        {scan?.scan_state?.replaceAll('_', ' ')}
                      </td>
                      {resultsList.map((result, i) => {
                        return <td key={i}>{result?.eggs_per_gram ?? '-'}</td>
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div>
          {parasites &&
            parasites.map(parasite => {
              const parasiteName = parasite.description[0].toUpperCase() + parasite.description.slice(1)
              return (
                batchReport?.buckets?.[parasite.description] && (
                  <div className='mb-3 parasite-text' key={parasite.description}>
                    <p className='mb-1'>
                      {parasite.description === 'anoplocephala'
                        ? parasite.display_name
                        : parasiteName.replaceAll('_', ' ')}
                    </p>
                    <div className='ml-5 api-text'>{batchReport.buckets[parasite.description]}</div>
                  </div>
                )
              )
            })}
        </div>
      </div>
    )}
      </ThemeContext.Consumer>
    )
  }
}

function myTextArea(batchDetails, t) {
  if (
    batchDetails == null
  ) {
    return <>
    </>
  } else {
  return (
    <ThemeContext.Consumer>
    {({theme, darkMode}) => (
    <>
    <TextareaAutosize
      id='textArea'
      name="comment"
      className="report-comments"
      type="text"
      wrap='soft'
      autoComplete='on'
      placeholderTextColor='blue'
      placeholder={batchDetails.comment ? batchDetails.comment.length > 0 ? batchDetails.comment : t('Characterlimit1000'): t('Characterlimit1000')}
      title='Insert Additional Comments'
      style={{ backgroundColor: theme.inputcolor, color: theme.textcolor }}
    />
   </> 
       )}
       </ThemeContext.Consumer>
    )
  }
}



const BatchReport = ({fetchBatchReport, editBatchScanComment, fetchBatchDetails, selectRH, batchReport, device, getPracticeData, batchDetails, getPracticeDataIndex, fetchedPracticelabel, dispatch}) => {

  const [practiceEmblemState, setEmblem] = useState(emblemEnum.left);

  useEffect(() => {
    fetchBatchReport(selectRH.batch_id)
  }, [fetchBatchReport, selectRH])

  useEffect(() => {
    fetchBatchDetails((selectRH.batch_id));
  }, [fetchBatchDetails, selectRH]);


  useEffect(() => {
    getPracticeDataIndex();
  }, []);

  const getLogo = useCallback(async () => {
    await getPracticeData()
  }, [getPracticeData])
  useEffect(() => {
    getLogo()
  }, [getLogo])

  const { t } = useTranslation();
  return (
    <>
    <ThemeContext.Consumer>
        {({theme, darkMode}) => (
      <div className='dr-container' >
        <div className='white-background-customers'>
          <div className='table-search-wrapper'>
            <h3 style={{color:theme.textcolor}} className='dr-header'>{t('batchReport')}</h3>
          </div>
        </div>
        <div className='dr-button-row  mt-4'>
          <NavLink to='/results-history'>
            <button data-umami-event='Batch Report: Back Button' type='button' className='dr-register-button dr-back'>
              {t('back')}
            </button>
          </NavLink>
           
          {batchReport !== null && batchReport.batch_scans.length !== 0 && (
            <div className='container d-flex justify-content-end' style={{padding:'0px'}}>
              <div className='row rh-print-buttons justify-content-end'>
                <div className=''>
                  {/* <FaCloudDownloadAlt className='' size={'100%'} onClick={this.setEmblemRight}></FaCloudDownloadAlt> */}
                  <button data-umami-event='Batch Report: Download PDF' onClick={downloadPDF} type='button' className='dr-register-button dr-print'>
                    {t('📥	 PDF')}
                  </button>
                </div>
                <div className=''>
                  <button data-umami-event='Batch Report: Print' onClick={window.print} type='button' className='dr-register-button dr-print'>
                    {t('print')}
                  </button>
                </div>
              </div>
            </div>
          )}
   
        </div>
        <div id='detailed-report-wrapper' className='detailed-report-wrapper' style={{ backgroundColor: theme.background, color:theme.textcolor}} >
          <div
            className='dr-print-wrapper section-to-print-detailed-width'
            id='section-to-print'
            style={{ backgroundColor: theme.background}}
          >
          <div className='container d-flex justify-content-end noprint'>
                  <div className='row align-buttons noprint' id='no-print-align'>
                    <div className='col d-flex justify-content-center noprint'>
                      <FaAlignLeft data-umami-event='Batch Report: Align left'  style={{ color: `${practiceEmblemState === emblemEnum.left ? theme.blue : theme.textcolor}` }}  className='align-right noprint' size={'100%'} onClick={() => setEmblem(emblemEnum.left)}></FaAlignLeft>
                    </div>
                    <div className='col'>
                      <FaAlignCenter data-umami-event='Single Report: Align center'  style={{ color: `${practiceEmblemState === emblemEnum.center ? theme.blue : theme.textcolor}` }} className='align-right noprint' size={'100%'} onClick={() => setEmblem(emblemEnum.center)}></FaAlignCenter>
                    </div>
                    <div className='col'>
                      <FaAlignRight data-umami-event='Single Report: Align right' style={{ color: `${practiceEmblemState === emblemEnum.right ? theme.blue : theme.textcolor}` }}  className='align-right noprint' size={'100%'} onClick={() => setEmblem(emblemEnum.right)}></FaAlignRight>
                    </div>
                  </div>
          </div>

            <div className='container'>
              <div id='print-style' className='mx-auto print-styling' style={{ color:theme.textcolor, backgroundColor: theme.background}}>
                  {batchReport === null ? (
                    <p className='individual-report-title'>{t('loading')}...</p>
                  ) : batchReport !== null && batchReport.batch_scans.length !== 0 ? (
                    <>
                    {/* This is the practice emblem  */}
                    {device.myPractice && (
                      <div className='pt-4 pb-1 d-flex align-items-end report-logo'>
                        {practiceEmblemState !== emblemEnum.left? (
                            <div className='col'></div>
                        ) : (<></>)}
                          <img src={device.myPractice} alt=''/>
                          {practiceEmblemState === emblemEnum.center ? (
                            <div className='col'></div>
                        ) : (<></>)}
                      </div>
                    )}
                  </>
                  ) : (
                    <p className='individual-report-title'>{t('noReportAvailable')}.</p>
                  )}
            
            <div className='col pr-3' >
             {detailColumn(batchReport, t, fetchedPracticelabel)}
            </div>
          </div>

          <div className='table-search-wrapper' >
              <h4 id='commentsbr'  style={{ backgroundColor: theme.background, color:theme.textcolor}} className='report-header-text'>{t('comments')}</h4>
          </div>

          <div className=''>
          <div id='currentBatchScanComment' style={{ backgroundColor: theme.background, color:theme.textcolor}}  className='mr-4 flex-wrapper-report_comment commentbr'>
                <div><b>{t('currentBatchScanComment')}:</b></div>
                <br></br>
                <p className='current_comment_span' id ='current_comment_span'> {batchDetails ? typeof(batchDetails) !== object & typeof(batchDetails) !== 'object' ? '' : batchDetails.comment === null ? '' : batchDetails.comment : ''}</p>   
          </div>
          <h4
              className='mb-4'
              style={{
                paddingTop: '5px',
                paddingBottom: '15px',
                paddingRight: '10px',
                borderBottom: '1px solid #4472C4',
                fontSize: '20px',
                marginRight:'5%'
              }}
              >
          </h4>
            <div id='no-print' style={{ backgroundColor: theme.background, color:theme.textcolor}} >
              <div className="scan-inputs-properties noprint" >
              <span id='comment_instruction_span' >{batchDetails ? typeof(batchDetails) !== object & typeof(batchDetails) !== 'object' ? (t('addComment')) : batchDetails.comment === null ?( t('addComment')) : batchDetails.comment.length > 1 ?( t('editComment')) : t('addComment') : ''}</span> 
                <div className='mr-5'>{myTextArea(batchDetails, t)}</div>
              </div>
            <div className='col-md-8 noprint'>    
                <button data-umami-event='Batch Report: Edit or Add Batch Comment' className='dr-submit-button noprint' onClick={() => onSubmit(editBatchScanComment, selectRH.batch_id, dispatch, t)}>
                  <span id='comment_button_text_span' >{batchDetails ? typeof(batchDetails) !== object & typeof(batchDetails) !== 'object' ?  ( t('add')) : batchDetails.comment === null ?( t('add')) : batchDetails.comment.length > 1 ?( t('edit')) : t('add'): ''}</span> 
                </button>
            </div>
          </div>
          </div>
          </div>
        </div>
        </div>
      </div>
            )}
            </ThemeContext.Consumer>
    </>
    
  )
}

function onSubmit(editBatchScanComment, id, dispatch, t){
  
  let textArea = document.getElementById('textArea');
  let input = textArea.value
  const editRequest = {
    comment:input 
  };

  if(input.length > 1000){
    dispatch({
      type: USER_ALERT,
      message: t('tooManyChars'),
      alertType: 'Warning',
    })
    return
  }
  
  let comment_instruction_span = document.getElementById('comment_instruction_span');
  let comment_button_text_span = document.getElementById('comment_button_text_span');
  let current_comment_span = document.getElementById('current_comment_span');

  editBatchScanComment(editRequest, id );

  current_comment_span.innerText = input   
  if(comment_instruction_span.innerText === 'Add Comment'){
    comment_instruction_span.innerText = 'Edit Comment'
  }
  if(comment_button_text_span.innerText === 'Add'){
    comment_button_text_span.innerText = 'Edit'
  }
  current_comment_span.innerText = input
};


function downloadPDF(){

  // in this fucntion we 
  // force the elements to be a printable color ie black text on white background 
  // take a pdf screenshot
  // then we switch it back

  let down_section = document.getElementById("section-to-print")
  let print_style = document.getElementById("print-style")
  let non_print_section_align = document.getElementById('no-print-align')
  let non_print_section = document.getElementById('no-print')
  let detailed_report_wrapper = document.getElementById('detailed-report-wrapper')
  let batch_table = document.getElementsByClassName('results-table')
  let batch_table_header = document.getElementById('batch-table')
  let currentBatchScanComment = document.getElementById('currentBatchScanComment')
  let commentsbr = document.getElementById('commentsbr')
  let batch_id = document.getElementById('br-id').innerText.replace(/\s/g, '');
  let date = document.getElementById('br-date').innerText.replace(/\s/g, '');
  let filename = 'Batch-Report_' + batch_id + '_' + date

  let prev_header_style = batch_table_header.style.backgroundColor
  let prev_table_style_1 = batch_table[0].style.backgroundColor
  let prev_table_style_2 = batch_table[1].style.backgroundColor

  // switching everything to white and back
  for(var i = 0; i < batch_table.length; i++) {
    batch_table[i].style.backgroundColor = "white"
    batch_table[i].style.color = "black"
  }

  currentBatchScanComment.style.backgroundColor = "white"
  currentBatchScanComment.style.color = "black"
  commentsbr.style.backgroundColor = "white"
  commentsbr.style.color = "black"
  batch_table_header.style.backgroundColor = "white"
  batch_table_header.style.color = "black"
  down_section.style.backgroundColor = "white"
  print_style.style.backgroundColor= "white"
  down_section.style.minWidth = "1264px"
  // down_section.style.minHeight = "1264px"
  // down_section.style.width = '600px'
  down_section.style.color = "black"
  print_style.style.color= "black"
  
  // taking pdf screenshot parts we dont want a screenshot of
  // we also hide 
  if(non_print_section && non_print_section_align){
    non_print_section.setAttribute('hidden', 'true')
    non_print_section_align.setAttribute('hidden', 'true')
    generatePDF(() => down_section, {
      method: "save",
      filename: filename,
      page: { margin: Margin.MEDIUM },
    }); 
    non_print_section_align.removeAttribute('hidden')
    non_print_section.removeAttribute('hidden')
  }
  // switch everything back to normal color

  for(var i = 0; i < batch_table.length; i++) {
    if(i%2)
      {
        batch_table[i].style.backgroundColor = prev_table_style_2
      }
    else{
      batch_table[i].style.backgroundColor = prev_table_style_1
    }
    batch_table[i].style.color = detailed_report_wrapper.style.color
  }

  batch_table_header.style.backgroundColor = prev_header_style
  batch_table_header.style.color = detailed_report_wrapper.style.color
  commentsbr.style.backgroundColor = detailed_report_wrapper.style.backgroundColor
  commentsbr.style.color = detailed_report_wrapper.style.color
  currentBatchScanComment.style.backgroundColor = detailed_report_wrapper.style.backgroundColor
  currentBatchScanComment.style.color = detailed_report_wrapper.style.color
  down_section.style.minWidth = null
  down_section.style.backgroundColor = detailed_report_wrapper.style.backgroundColor
  print_style.style.backgroundColor = detailed_report_wrapper.style.backgroundColor
  down_section.style.color = detailed_report_wrapper.style.color
  print_style.style.color= detailed_report_wrapper.style.color
}

const mapStateToProps = state => ({
  selectRH: state.selectRH,
  batchReport: state.batchDetails.batchReport,
  batchDetails: state.batchDetails.batch_details,
  
  fetchedPracticelabel: state.getPracticeDataIndex,

  fetchedScan: state.scan.scan,
  device: state.device,
  practiceEmblemState: state.practiceEmblemState,
})
 
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchBatchReport,
      fetchBatchDetails,
      getPracticeData,
      editBatchScanComment,
      getPracticeDataIndex,
      dispatch
    },
    dispatch
  )
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(BatchReport))
