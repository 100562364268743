import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import DataTable from 'react-data-table-component'
import { withRouter, NavLink, Link } from 'react-router-dom'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import TitleCase from 'react-title-case'
import history from '../../history'
import {ThemeContext} from '../../context/theme-context';
import { equipmentPageHandler } from "../../redux/actions/tablePaginationActions"
import {
  fetchEquipment,
  selectEquipment,
  fetchDeviceAuthorisations,
  fetchScanCredits,
  fetchLatestUpdate
} from '../../redux/actions/fetchEquipmentActions'

import {
  getPracticeDataIndex,
} from '../../redux/actions/fetchResultHistoryActions'

import { withTranslation } from 'react-i18next'
import equipmentTablePage from '../../redux/reducers/equipmentTablePage'
import { Details } from '@material-ui/icons'

const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data, null, 2)}</pre>;


class Equipment extends Component {
  constructor() {
    super()
    this.state = {
      filterText: '',
      showDetailPreview: false,
      showBatchPreview: false,
      selectedRow: {},
      selectedRowID: null,
      loading: false,
      page: 1,
      filteredData: [],
    }
  }

  async componentDidMount() {
    const { fetchEquipment, getPracticeDataIndex, fetchScanCredits, fetchLatestUpdate } = this.props
    await getPracticeDataIndex();
    await fetchScanCredits();
    await fetchEquipment(this.props.equipmentTablePage, this.props.fetchedPracticeIndex.practice)
    await fetchLatestUpdate();

    
    this.scanCreditDict = {}
    for(let i=0; i<this.props.fetchedScanCredits.credits.length; i++){
      this.scanCreditDict[this.props.fetchedScanCredits.credits[i]['credit_type']['$ref']] = this.props.fetchedScanCredits.credits[i]['quantity']
    }
  }

  handlePageChange = page => {
    this.props.fetchEquipment(page, this.props.fetchedPracticeIndex.practice)
    this.props.equipmentPageHandler(page)
  }

  renderEquipment() {
    if (this.props.equipment !== null) {
      return this.props.equipment.equipment.map(equipment => {
        if (equipment.serial_number === 'None'){
          equipment.serial_number = ''
        }
        if (equipment.description === 'None'){
          equipment.description = ''
        }
        if (equipment.update_version === 'None'){
          equipment.update_version = ''
        }
        return {
          id: equipment.$uri.split('/').pop(),
          description: equipment.description,
          serial_number: equipment.serial_number,
          update_version: equipment.update_version, 
        }
      })
    }
  }


  handleChange = event => {
    this.setState({ filterText: event.target.value })
    this.props.fetchEquipment(this.props.equipmentTablePage, this.props.fetchedPracticeIndex.practice)
  }

  clearSearch = () => {
    this.setState({ filterText: '' })
    this.props.fetchEquipment(this.props.equipmentTablePage, this.props.fetchedPracticeIndex.practice)
  }

  // actionOptions = row => {
  //   return (
  //     <div>
  //       <Dropdown.Item
  //         onClick={() => {
  //           this.detailPreview(row)
  //         }}
  //       >
  //         <div className='table-edit'>{this.props.t('scanDetails')}</div>
  //       </Dropdown.Item>
  //       {row.batch_id !== 'none' && (
  //         <Dropdown.Item
  //           onClick={() => {
  //             this.batchPreview(row)
  //           }}
  //         >
  //           <div className='table-delete'>{this.props.t('batchDetails')}</div>
  //         </Dropdown.Item>
  //       )}
  //     </div>
  //   )
  // }

  componentDidUpdate(prevProps) {
    if (this.props.equipment !== prevProps.equipment) {
      const tableData = this.renderEquipment()
      this.setState({ filteredData: tableData })
    }
    // this.props.getPracticeDataIndex();


  }

  selectRow = async row => {
    this.setState(prevState => ({
      selectedRow: {
        ...prevState.selectedRow,
        row,
      },
    }))
    await this.props.fetchDeviceAuthorisations(row.id)
    this.clickedDeviceData = this.props.fetchDeviceAuth 
  }


  rowExpanded = row => {

    return(
      <ThemeContext.Consumer>
      {({theme}) => (
      <div className='equipment_expandable' style={{background: theme.foreground, color: theme.textcolor}}>
      { this.props.fetchDeviceAuth && this.props.fetchDeviceAuth.data.length > 1 ? 
        <div className='container'>
                  <div className='row'>
                      <div className='col'>
                       Analyzer Type:  {/* {this.props.fetchDeviceAuth.data[0].credittype.description} */}
                      </div>
                      <div className='col'>
                        {this.props.fetchDeviceAuth.data[0].credittype.description}
                      </div>
                  </div>
                  <div className='row'>
                      <div className='col'>
                        Scan Credits for {this.props.fetchDeviceAuth.data[0].credittype.description} Devcies:
                      </div>
                      <div className='col'>
                        {this.scanCreditDict ? JSON.stringify(this.scanCreditDict[this.props.fetchDeviceAuth.data[0].credittype.$ref]):''}
                      </div>
                  </div>
                  <div className='row'>
                      <div className='col'>
                        Update Required:
                      </div>
                      <div className='col'>
                        {this.state.selectedRow.row?.update_version ? (this.props.fetchedLatestUpdate != this.state.selectedRow?.row?.update_version ? 'True' : 'False' ): 'Unkown'}
                      </div>
                  </div>
      </div>
      :''}
      </div>
    )}
   </ThemeContext.Consumer>
    )
  }

  render() {
    let conditionalRowStyles
    if (this.state.selectedRow.row !== undefined) {
      conditionalRowStyles = [
        {
          when: row => row.id === this.state.selectedRow.row.id,
          style: {
            color: ThemeContext.Consumer._currentValue.theme.blue,
          },
        },
      ]
    }

    const customStyles = {
      title: {
        style: {
          fontWeight: '900',
          // backgroundColor:ThemeContext.Consumer._currentValue.theme.foreground ,
        }
      },
      rows: {
        style: {
          // color:ThemeContext.Consumer._currentValue.theme.textcolor,
          fontFamily: 'sans-serif',
          fontSize: '14px',
          minHeight: '38px',
          maxHeight: '40px',
          backgroundColor: ThemeContext.Consumer._currentValue.theme.background,

        }
      },
      headCells: {
        style: {
          // color:ThemeContext.Consumer._currentValue.theme.textcolor,
          fontFamily: 'sans-serif',
          fontSize: '14px',
          minHeight: '38px',
          backgroundColor: ThemeContext.Consumer._currentValue.theme.foreground,
        },
      },
      cells: {
        style: {
          fontSize: '15px',
          backgroundColor: ThemeContext.Consumer._currentValue.theme.background,
          // background: '#73AD21';
        },
      },

    }

    const columns = [
      {
        name: this.props.t('ID'),
        selector: 'id',
        grow: 1,
      },
      {
        name: this.props.t('Serial Number'),
        selector: 'serial_number',
        grow: 1,
      },
      {
        name: this.props.t('Description'),
        selector: 'description',
        grow: 1,
        center: true,
        hide: 'sm',
      },
      {
        name: this.props.t('Update Version'),
        selector: 'update_version',
        grow: 1,
        center: true,
      },
    ]


    return (
      <ThemeContext.Consumer>
      {({theme, darkMode}) => (
      <div>
        <div className='white-background-customers'>
          <div className='table-search-wrapper'> 
            <h3 style={{color:theme.textcolor}} className='header-worklist'>{this.props.t('Equipment')}</h3>
          </div>
          <div className='row rh-table-wrapper'>
            <div
              className={
                this.state.showDetailPreview || this.state.showBatchPreview
                  ? 'col-xl-12 col-12'
                  : 'col-xl-12 col-12'
              }
            >
              <DataTable
                columns={columns}
                data={this.state.filteredData}
                pagination
                onChangePage={this.handlePageChange}
                paginationTotalRows={parseInt(this.props.count)}
                paginationDefaultPage={this.props.equipmentTablePage}
                paginationServer
                paginationPerPage={10}
                expandableInheritConditionalStyles={false}
                paginationRowsPerPageOptions={[10]}
                onRowClicked={this.selectRow}
                expandOnRowClicked={true}
                expandableRowsComponent={this.rowExpanded(this.props)}
                // expandableIcon = { <svg>...</svg> }
                expandableRowExpanded={(row) => (row == this.state.selectedRow.row)}
                expandableRowsHideExpander={true}
                conditionalRowStyles={conditionalRowStyles}
                customStyles={customStyles}
                responsive={true}
                expandableRows={true}
                theme={darkMode ? 'solarized' : ''}
              />
            </div>
          </div>
        </div>
      </div>
          )}
    </ThemeContext.Consumer>
    )
  }
}

const mapStateToProps = state => ({
  equipment: state.equipment.equipment,
  selectEquipment: state.selectEquipment,
  count: state.equipment.count,
  equipmentTablePage: state.equipmentTablePage.page,
  fetchedPracticeIndex: state.getPracticeDataIndex.id,
  fetchDeviceAuth: state.fetchDeviceAuthorisations.data,
  fetchedScanCredits: state.fetchScanCredits.data.data,
  fetchedLatestUpdate: state.fetchLatestUpdate.data
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      selectEquipment,
      fetchEquipment,
      equipmentPageHandler,
      getPracticeDataIndex,
      fetchDeviceAuthorisations,
      fetchScanCredits,
      fetchLatestUpdate,
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(withTranslation()(Equipment))
