import { combineReducers } from 'redux'

import sideMenuReducer from './sideMenuReducer'
import sideMenuClearReducer from './sideMenuClearReducer'
import loginReducer from './loginReducer'
import signupReducer from './signupReducer'
import dashboardReducer from './dashboardReducer'
import fetchResultsReducer from './fetchResultsHistoryReducer'
import selectRHReducer from './selectResultHistoryReducer'
import selectRHDetailsReducer from './fetchResultsHistoryDetailsReducer'
import selectedScan from './fetchScanReducer'
import selectedPracticeProduct from './fetchPracticeProductReducer'
import selectedScanImages from './fetchImagesReducer'
import fetchWorklistReducer from './fetchWorklistReducer'
import editWorklistReducer from './editWorklistReducer'
import deleteWorklistReducer from './deleteWorklistReducer'
import deviceReducer from './deviceReducer'
import batchReducer from './fetchBatchDetailsReducer'
import userAlertReducer from './userAlertReducer'
import rhPreviousReducer from './setRHPreviousPageReducer'
import pendingScansTablePage from './pendingScansTablePage'
import resultsHistoryTablePage from './resultsHistoryTablePage'
import selectedSpecies from './fetchScanSpecies'
import getPracticeDataIndex from './fetchPracticeDataIndexReducer'
import getPracticeLabel from './fetchPracticeLabelReducer'
import fetchPracticeDocumentationReducer from './fetchPracticeDocumentationReducer'
import fetchScanCredits from './fetchScanCredits'
import equipmentTablePage from './equipmentTablePage'
import selectEquipment from './selectEquipment'
import fetchEquipment from './fetchEquipment'
import fetchDeviceAuthorisations from './fetchDeviceAuthReducer'
import fetchLatestUpdate from './fetchLatestUpdate'

// history: state.equipment.history,
// selectRH: state.selectRH,
// count: state.resultsHistory.count,
// resultsHistoryTablePage: state.resultsHistoryTablePage.page,

// combinereducers is used when multiple reducers are present
export default combineReducers({
  sideMenu: sideMenuReducer,
  sideMenuClear: sideMenuClearReducer,
  resultsHistory: fetchResultsReducer,
  security: loginReducer,
  signup: signupReducer,
  dashboard: dashboardReducer,
  selectRH: selectRHReducer,
  rhDetails: selectRHDetailsReducer,
  scan: selectedScan,
  species: selectedSpecies,
  products: selectedPracticeProduct,
  images: selectedScanImages,
  worklist: fetchWorklistReducer,
  worklistIs404:fetchWorklistReducer,
  editWorklist: editWorklistReducer,
  deleteWorklist: deleteWorklistReducer,
  device: deviceReducer,
  batchDetails: batchReducer,
  practiceDocumentation: fetchPracticeDocumentationReducer,
  userAlert: userAlertReducer,
  fetchScanCredits:fetchScanCredits,
  resultsPreviousPage: rhPreviousReducer,
  pendingScansTablePage: pendingScansTablePage,
  resultsHistoryTablePage: resultsHistoryTablePage,
  getPracticeLabel: getPracticeLabel,
  getPracticeDataIndex: getPracticeDataIndex,
  fetchDeviceAuthorisations: fetchDeviceAuthorisations,
  equipmentTablePage: equipmentTablePage,
  selectEquipment: selectEquipment,
  equipment: fetchEquipment,
  fetchLatestUpdate: fetchLatestUpdate
})
