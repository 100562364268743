import React from "react";
import { useTranslation } from "react-i18next";
import { ThemeContext } from '../context/theme-context'

const ContactUsOption = ({ children, h2Text, pText, mailTo }) => {
  const {t} = useTranslation()

  return (
    <ThemeContext.Consumer>
      {({ theme, darkMode }) => (
      <div className="contactus-option" style={{ backgroundColor: theme.background }}>
        <div className="icon-wrapper">
          {children}
        </div>
        <div className="contactus-option-text">
          <h2 >{h2Text}</h2>
          <p>{pText}</p>
        </div>
        <a href={mailTo}>
          <span style={{ color: "#00a0ee" }}>{t('sendNow')}</span>
        </a>
      </div>
      )}
    </ThemeContext.Consumer>
  ); 
};
export default (ContactUsOption);
