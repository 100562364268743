import axios from 'axios'
import history from './history'
import store from './store'
import { SET_CURRENT_USER } from './redux/actions/types'

export const version = '2024.10.24.02' // cache version / change to tag when pushing to production
// A reverse proxy is used on resources not found (setupProxy.js)
export const backInstance = axios.create({
  baseURL: '/api',
})

export const IMAGES_PER_REPORT = 6 // the number of images you can send from the image gallery to the single report

// Insert the cognito access token to the authorization header before each API request
backInstance.interceptors.request.use(function (config) {
  const accessToken = localStorage.getItem('AccessToken')
  config.headers['Authorization'] = `Bearer ${accessToken}`
  config.headers['credentials'] = 'same-origin'
  config.headers['Accept'] = 'application/json'
  config.headers['Content-Type'] = 'application/json'
  config.headers['Access-Control-Allow-Headers'] = '*'
  config.headers['crossDomain'] = 'true'
  config.headers['Access-Control-Expose-Headers'] = `X-Total-Count`
  return config
})

backInstance.interceptors.response.use(
  function (response) {
    return response
  },
  function (error) {
    if (error.response.status === 401 || error.response.status === 403) {
      localStorage.removeItem('AccessToken')
      localStorage.removeItem('state')
      localStorage.removeItem('email')
      localStorage.removeItem('rememberMe')
      localStorage.removeItem('practiceDocumentation') 
      localStorage.removeItem('credtTypeproductAuth') 
      localStorage.removeItem('largestCreditType') 
      
      store.dispatch({
        type: SET_CURRENT_USER,
        payload: null,
        tokenExp: null,
      })
      history.push('/')
    }

    return Promise.reject(error)
  }
)


