import React from "react";
import Input from "./Input";
import Select from "./Select";
import { withTranslation } from 'react-i18next'

function InputField({ label, fieldType, ...inputProps }) {
  let field;
  if (fieldType === "select") {
    field = <Select {...inputProps} />;
  } else {
    field = <Input {...inputProps} />;
  }

  return (
    <div className="input-field-wrapper">
      {label && <label>{this.props.t(label)}</label>}
      {field}
    </div>
  );
}

export default withTranslation()(InputField);
