// import {fetchScanProducts, fetchSpecies, fetchpracticeproducts} from './redux/actions/scanActions'
// import { fetchPracticeDocumentation } from "./redux/actions/fetchPracticeDocumentation";

const workercode = () => {
  onmessage = function(e) {
    // self.importScripts("./testModule"); // eslint-disable-line no-restricted-globals
    // eslint-disable-line no-restricted-globals
    let time_limit = 1 * 30 * 60 * 1000
    // console.log("Updating Cache data in ", time_limit / 1000 / 60 , ' minutes');
    setInterval(() => {
      var workerResult = "Cache data updated";
      postMessage(workerResult); // eslint-disable-line no-restricted-globals
    }, time_limit);
  };
};

let code = workercode.toString();
code = code.substring(code.indexOf("{") + 1, code.lastIndexOf("}"));

const blob = new Blob([code], { type: "application/javascript" });
const worker_script = URL.createObjectURL(blob);

export default worker_script;