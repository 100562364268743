import { PENDING_SCANS_TABLE_PAGE } from "../actions/types";

const initialState = {
  page: 1,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PENDING_SCANS_TABLE_PAGE:
      return {
        ...state,
        page: action.payload,
      };

    default:
      return state;
  }
};
