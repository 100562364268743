import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { passwordReset } from "../redux/actions/securityActions";
import Button from '../components/Button';
import InputField from '../components/InputField';
import { withTranslation } from 'react-i18next'

class ForgotPassword extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      valEmail: "",
    };
  }

  handleChange = (event) => {
    const input = event.target;
    const value = (input.type === 'checkbox') ? input.checked : input.value;
 
    this.setState({ [input.name]: value });
  };

  // Check input fields for errors
  validate = () => {
    let isError = false;

    if (this.state.email.length < 3 || this.state.email.length > 100) {
      isError = true;
      this.setState({
        valEmail: this.props.t('emailMustMoreThan3Characters')
      });
    }

    if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.state.email)) {
      isError = true;
      this.setState({
        valEmail: this.props.t('notAValidEmail')
      });
    }

    return isError;
  };

  handleFormSubmit = (e) => {
    e.preventDefault();

    this.setState({
      loading: true,
      valEmail: "",
      valPassword: ""
    });

    const err = this.validate();

    if (!err) {
      const PasswordResetRequest = {
        email: this.state.email
      };

      this.props.passwordReset(PasswordResetRequest);
    }
  };

  render() {
    const redirectToReferrer = this.state.redirectToReferrer;
      if (redirectToReferrer === true) {
        return <Redirect to={{
          pathname: '/',
          state: { email: this.state.email }
        }}/>
      }
    const {
      valEmail,
    } = this.state;

      return(

        <div className="wrapper-login-container">
        <div className="row">
            <div className="col"></div>
            <div className="opacity-background">
            <div className="login-container" id="login">
              <p className="login-header">{this.props.t('forgotPassword')}</p>
              <div className="header2-login" ><span style={{ marginLeft: "5px" }}>{this.props.t('enterYourEmailAddressToResetYourPassword')}</span>
             </div>
              <form onSubmit={this.handleFormSubmit}>
                <label className="labels">{this.props.t('email')}</label>
                <InputField className="input-field" name="email" value={this.state.email} placeholder={this.props.t('enterEmailAddress')} type="email" onChange={this.handleChange} />
                {valEmail && <div className="invalid-feedback d-block">{valEmail}</div>}
                <Button value='submit' styleClass="btn-login btn-primary" type="submit" />
                <div className="fp-link">
                  <Link to="/">{this.props.t('back')}</Link>
                </div>
              </form>
            </div>
          </div>    
        </div>
       </div> 
      );
  }
}

const mapStateToProps = state => ({
  forgotPass: state.forgotPass,
  errors: state.errors
});

export default withTranslation()(connect(mapStateToProps, { passwordReset })(ForgotPassword));