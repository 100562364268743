import React, { createContext, useContext, useEffect, useState } from 'react'
import i18n from '../i18next'

const langContext = createContext({
  locale: '',
  setLocale: () => {},
})

export const useLangContext = () => useContext(langContext)

const LangProvider = ({ children }) => {
  const [locale, setLocale] = useState(i18n.language)

  useEffect(() => {
    i18n.on('languageChanged', () => setLocale(i18n.language))
  }, [])

  useEffect(() => {
    i18n.changeLanguage(locale)
  }, [locale])

  const ctx = {
    locale,
    setLocale,
  }
  return <langContext.Provider value={ctx}>{children}</langContext.Provider>
}

export default LangProvider
