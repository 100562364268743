import {
  FETCH_EQUIPMENT_FAILURE,
  FETCH_EQUIPMENT_START,
  FETCH_EQUIPMENT_SUCCESS,
  EQUIPMENT_SELECTED,
  FETCH_DEVICE_AUTH,
  FETCH_SCAN_CREDITS,
  FETCH_LATEST_UPDATE
} from './types'
import { backInstance } from '../../config'

const fetchEquipmentStart = () => {
  return {
    type: FETCH_EQUIPMENT_START,
  }
}

const fetchEquipmentSuccess = (equipment, count) => {
  return {
    type: FETCH_EQUIPMENT_SUCCESS,
    equipment,
    count,
  }
}

const fetchEquipmentFailure = error => {
  return {
    type: FETCH_EQUIPMENT_FAILURE,
    error,
  }
}

export const fetchDeviceAuthorisations = id => {
  return async dispatch => {
    const response = await backInstance.get(`/device/${id}/product_authorisations`)
    dispatch({ type: FETCH_DEVICE_AUTH, payload: response})
  }
}

export const fetchLatestUpdate = (channel='stable') => {
  return async dispatch => {
    const response = await backInstance.get(`/devicesoftwareupdate/current?channel=${channel}`)
    dispatch({ type: FETCH_LATEST_UPDATE, payload: response.data})
  }
}


export const fetchScanCredits= id => {
  return async dispatch => {
    const response = await backInstance.get(`/scancredit/credits`)
    dispatch({ type: FETCH_SCAN_CREDITS, payload: response})
  }
}

const fetchEquipmentDetails = (page, practice_id, vars=false, column=false, newPerPage=10) => {

  vars = {
    "practice": {
      "$ref": `/practice/${practice_id}`
    },
  }
  try{
    let query = `device?where=${JSON.stringify(vars)}&page=${page}&per_page=${newPerPage}`
    if(column){
      query = query + `&sort=${JSON.stringify(column)}`
    }
    const response = backInstance.get(query)
    return response
  }
  catch(e){
    console.log('error', e)
  }
}


export const fetchEquipment =
  (page, practice_id,  searchTerm = '', sortCol=false, newPerPage=10) =>
  dispatch => {
    dispatch(fetchEquipmentStart())
    return fetchEquipmentDetails(page, practice_id, searchTerm, sortCol, newPerPage).then(
      response => dispatch(fetchEquipmentSuccess(response.data, response.headers['x-total-count'])),
      error => dispatch(fetchEquipmentFailure(error))
    )
  }

export const selectEquipment= (serial_number) => {
  return {
    type: EQUIPMENT_SELECTED,
    serial_number: serial_number,
  }
}
