import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "../src/store";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { saveState } from "./localStorage";
import UserAlertModal from "./components/UserAlertModal";
import "./css/main.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import LangProvider from "./context/LangContext";
import {ThemeContext, themes} from './context/theme-context';

store.subscribe(() => {
  saveState(store.getState());
});

const app = (
  <BrowserRouter>
     <ThemeContext.Provider>
      <Provider store={store}>
        <LangProvider>
          <UserAlertModal />
          <App />
        </LangProvider>
      </Provider>
    </ThemeContext.Provider>
  </BrowserRouter>
);

ReactDOM.render(app, document.getElementById("root"));
serviceWorker.unregister();
