import { FETCH_SCAN_SPECIES } from '../actions/types'

const initialState = {
  scan: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SCAN_SPECIES:
      return {
        ...state,
        scan: action.payload,
      }
    default:
      return state
  }
}
