import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { login } from "../redux/actions/securityActions";
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next'

import Button from "../components/Button";
import InputField from "../components/InputField";
import ErrorModal from "../components/ErrorModal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

class Signup extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      username: "",
      password: "",
      rememberMe: false,
      valUsername: "",
      valEmail: "",
      valPassword: "",
      show: false,
      loading: false,
      login: false,
      showPassword: false,
    };
    this.onSubmit = this.handleFormSubmit.bind(this);
    this.onChange = this.handleChange.bind(this);
  }

  handleChange = (event) => {
    const input = event.target;
    const value = input.type === "checkbox" ? input.checked : input.value;

    this.setState({ [input.name]: value });
  };

  errorPopUp = () => {
    if (this.props.errors !== null) {
      return (
        <ErrorModal
          onHide={this.handleClose}
          handleClose={this.handleClose}
          show={this.state.show}
        />
      );
    }
  };

  onShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  // Check input fields for errors
  validate = () => {
    let isError = false;

    if (this.state.username.length < 6 || this.state.email.length > 64) {
      isError = true;
      this.setState({
        valUsername: this.props.t('usernameMustBeAtLeast6Characters'),
      });
    }

    if (this.state.email.length < 6 || this.state.email.length > 320) {
      isError = true;
      this.setState({
        valEmail: this.props.t('emailMustBeAtLeast6Characters'),
      });
    }

    if (
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.state.email)
    ) {
      isError = true;
      this.setState({
        valEmail: this.props.t('notAValidEmail'),
      });
    }

    if (this.state.password.length < 8 || this.state.password.length > 255) {
      isError = true;
      this.setState({
        valPassword: this.props.t('passwordMustBeAtLeast8Characters'),
      });
    }

    return isError;
  };

  handleFormSubmit = (e) => {
    e.preventDefault();

    const { email, rememberMe } = this.state;
    localStorage.setItem("rememberMe", rememberMe);
    localStorage.setItem("email", rememberMe ? email : "");

    this.setState({
      loading: true,
      valUsername: "",
      valEmail: "",
      valPassword: "",
    });

    const err = this.validate();

    if (!err) {
      const LoginRequest = {
        username: this.state.email,
        password: this.state.password,
      };

      this.props.login(LoginRequest);
    }
  };

  handleClose = () => {
    this.setState({ show: false });
  };

  render() {
    const { email, password, valPassword, valEmail, username, valUsername } = this.state;
    return (
      <div className="wrapper-login-container">
        <div className="row">
          <div className="col"></div>
          <div className="opacity-background">
            <div className="login-container" id="login">
              <p className="login-header">{this.props.t('createAccount')}</p>
              <div className="header2-login">
                <Link to="/" style={{ color: "#232323" }}>
                  {this.props.t('alreadyHaveAnAccount')}
                  <span className="orange-color" style={{ marginLeft: "5px" }}>
                   {this.props.t('logIn')}
                  </span>
                </Link>
              </div>
              <form onSubmit={this.handleFormSubmit}>
                <label className="labels">{this.props.t('username')}</label>
                <InputField
                  className="input-field"
                  name="username"
                  value={username}
                  placeholder={this.props.t('insertUsername')}
                  onChange={this.handleChange}
                />
                {valUsername && <div className="invalid-feedback d-block">{valUsername}</div>}
                <label className="labels">{this.props.t('email')}</label>
                <InputField
                  className="input-field"
                  name="email"
                  value={email}
                  placeholder={this.props.t('insertAddress')}
                  onChange={this.handleChange}
                />
                {valEmail && <div className="invalid-feedback d-block">{valEmail}</div>}
                <span>
                  <label className="labels">{this.props.t('password')}</label>
                  {this.state.showPassword ? (
                    <FontAwesomeIcon
                      icon={faEye}
                      size="1x"
                      id="password-eye-icon"
                      onClick={this.onShowPassword}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faEyeSlash}
                      size="1x"
                      id="password-eye-icon"
                      onClick={this.onShowPassword}
                    />
                  )}
                </span>
                {this.state.showPassword ? (
                  <InputField
                    className="input-field"
                    name="password"
                    value={password}
                    placeholder={this.props.t('insertPassword')}
                    type="text"
                    onChange={this.handleChange}
                  />
                ) : (
                  <InputField
                    className="input-field"
                    name="password"
                    value={password}
                    placeholder={this.props.t('insertPassword')}
                    type="Password"
                    onChange={this.handleChange}
                  />
                )}
                {valPassword && <div className="invalid-feedback d-block">{valPassword}</div>}
                <Button value='signUp' styleClass="btn-login btn-primary" type="submit" />
                <div>{this.errorPopUp()}</div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Signup.propTypes = {
  login: PropTypes.func.isRequired,
  security: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  security: state.security,
  errors: state.security.errors,
});

export default withTranslation()(connect(mapStateToProps, { login })(Signup));
