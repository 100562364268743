import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Gallery } from 'react-grid-gallery'
import { bindActionCreators, compose } from 'redux'
import { withRouter, NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBackward } from '@fortawesome/free-solid-svg-icons'
import { fetchImages, clearImages } from '../../redux/actions/fetchResultHistoryActions'
import { withTranslation } from 'react-i18next'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import { ThemeContext } from '../../context/theme-context';
import { USER_ALERT } from '../../redux/actions/types'
import history from '../../history'
import { IMAGES_PER_REPORT } from '../../config'

import JSZip from 'jszip';

// @ts-ignore
import * as JSZipUtils from 'jszip-utils';

import { saveAs } from 'save-as'

const { REACT_APP_PORT_NUMBER, REACT_APP_TARGET_API } = process.env;

var target = REACT_APP_TARGET_API 

if (REACT_APP_PORT_NUMBER != "_blank") {
  target = `http://localhost:${REACT_APP_PORT_NUMBER}`
}

export class ImageGallery extends Component {
  constructor() {
    super()
    this.state = {
      index: -1,
      images: []
    }
    this.selectCount = 0
  }


  handleDownload = (downloadAll=false) => {

    let currentDate = new Date().toDateString().replaceAll(' ', '_');

    let zipFilename = 'OvaCyte_' +  'Selected_' +  'Scan_' + this.props.selectRH.scan_id + '_' + "Images" + '_' + currentDate
    let urlList = []
    let count = 0
    var zip = new JSZip();
    if(downloadAll){zipFilename = 'OvaCyte_' +  'Gallery_' +  'Scan_' + this.props.selectRH.scan_id + '_' + "Images" + '_' + currentDate}
    
    for (let i = 0; i < this.state.images.length; i++) {
      if(downloadAll){urlList.push(this.state.images[i].src)}
      else if (this.state.images[i].isSelected) { urlList.push(this.state.images[i].src) }
    }

    urlList.forEach(function (url, i) {
      var filename = urlList[i];

      let filename_split = filename.split('/');

      filename = filename_split[filename_split.length-2] + '_' + filename_split[filename_split.length-1] + '.jpeg'
      // loading a file and add it in a zip file
      JSZipUtils.getBinaryContent(url, function (err, data) {
        if (err) {
          // console.log(err)
          throw err; 
        }
        zip.file(filename, data, { 
          binary: true,
          compression: "DEFLATE",
          compressionOptions: {
               /* compression level ranges from 1 (best speed) to 9 (best compression) */
              level: 1
          }
        });
        count++;
        if (count == urlList.length) {
          zip.generateAsync({ 
            type: 'blob',
            compression: "DEFLATE",
            compressionOptions: {
                 /* compression level ranges from 1 (best speed) to 9 (best compression) */
                level: 1
            }
          }).then(function (content) {
            saveAs(content, zipFilename);
          });
        }
      });
    });
  }


  async componentDidMount() {
    this.selectCount = 0
    const { selectRH, fetchImages, clearImages } = this.props
    // clearImages()
    if (this.props.location.state) {
      await fetchImages(selectRH.scan_id, this.props.location.state.parasite)
    } else {
      await fetchImages(selectRH.scan_id)
    }

    this.images = this.makePhotoList(this.props.fetchedImages)

    this.setState({ images: this.images })
  }

  componentWillUnmount() {
    this.selectCount = 0
    this.setState({ images: [], index: -1 })
  }

  makePhotoList(images) {
    return images.map(item => {
      const photo = {
        src: `${target}${item.src}`,
        // src: `/api${item.src}`,
        thumbnailWidth: 200,
        thumbnailHeight: 150,
        thumbnail: `${target}${item.src}`,
        // thumbnail: `/api${item.src}`,
      }
      return photo
    })
  }

  // componentDidUpdate(){
  // }


  sendImagesToReport(images) {

    let imagesToBeSent = []
    for (let i = 0; i < images.length; i++) {
      if (images[i]['isSelected']) {
        images[i]['isSelected'] = false
        imagesToBeSent.push(images[i])
      }
    }
    if (imagesToBeSent.length > 0) {
      history.push({
        pathname: '/detailed-report',
        images: imagesToBeSent
      })
    }
  }

  handleSelect = (imagelist, index, item, event) => {

    if (imagelist[index].isSelected) {
      imagelist[index].isSelected = null
      this.selectCount = this.selectCount - 1
    }
    else {
      if (this.selectCount < IMAGES_PER_REPORT) { // can only select 5 images at once
        imagelist[index].isSelected = true
        this.selectCount = this.selectCount + 1
      }
      else {
        this.props.dispatch({
          type: USER_ALERT,
          message: this.props.t('cantSelectMoreImages'),
          alertType: 'Caution',
        })
      }
    }

    this.setState({
      images: imagelist,
    });

  };

  getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return width
  }

  handleOpenClick() {
    for (let index = 0; index < this.state.images.length; index++) {
      if (this.state.images[index].isSelected === true) {
        this.setState({ index })
        return
      }
    }
  }

  isTouchDevice() {
    return (('ontouchstart' in window) ||
      (navigator.maxTouchPoints > 0) ||
      (navigator.msMaxTouchPoints > 0));
  }

  handleClick(images, index, item, e) {

    if (this.isTouchDevice()) // double click to view image on mobile - single click to select image
    {
      if (e.detail === 2) {
        this.setState({ index })
      }
      else if (this.selectCount >= IMAGES_PER_REPORT) {
        if (!images[index].isSelected) {
          this.setState({ index })
        }
      }
      this.handleSelect(images, index)
    }
    else {
      this.setState({ index })
    }
  }
  handleClose = () => this.setState({ index: -1 })
  handleMovePrev = prevIndex => this.setState({ index: prevIndex })
  handleMoveNext = nextIndex => this.setState({ index: nextIndex })

  render() {

    var images;
    if (!this.images) { images = this.makePhotoList(this.props.fetchedImages) } else { images = this.images }
    const currentImage = images[this.state.index]
    const nextIndex = (this.state.index + 1) % images.length
    const nextImage = images[nextIndex] || currentImage
    const prevIndex = (this.state.index + images.length - 1) % images.length
    const prevImage = images[prevIndex] || currentImage

    // for(let i =0; i < images.length; i++){
    //   images[i]['isSelected'] = false
    // }

    return (
      <ThemeContext.Consumer>
        {({ theme, darkMode }) => (
          <div>
            <div className='white-background-customers' >
              <div className='table-search-wrapper'>
                <div>
                  <h3 className='header-worklist' style={{ backgroundColor: theme.foreground, color: theme.textcolor }}>{this.props.t('imageGallery')}</h3>
                  {/* only appear on mobile or small width and if something is selected */}

                  <p className='p-worklist'>
                    <NavLink to='/results-history' >
                      <FontAwesomeIcon data-umami-event='Gallery: Back Button' style={{ backgroundColor: theme.foreground, color: theme.textcolor }} icon={faBackward} size='1x' /> <span style={{ backgroundColor: theme.foreground, color: theme.textcolor }} >{this.props.t('back')}</span>
                    </NavLink>
                  </p>
                </div>


              </div>

              <div className='container'>
                      <div className='row'>
                        <div className='col'>
                          <button data-umami-event='Gallery: Download entire gallery' type='button' className={'btn btn-primary rh-detailed-selected'} onClick={() => { this.handleDownload(true) }}>
                            {this.props.t('downloadGallery')}
                            </button>
                            <button data-umami-event='Gallery: Download selected images' disabled={this.selectCount < 1} type='button' className={this.selectCount < 1 ? 'rh-register-button rh-detailed-selected-disabled' : 'btn btn-primary rh-detailed-selected'} onClick={() => { this.handleDownload(false) }}>
                              {this.props.t('download')}
                              </button>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col'>
                            <button data-umami-event='Gallery: Send images to single report' disabled={this.selectCount < 1} type='button' className={this.selectCount < 1 ? 'rh-register-button rh-detailed-selected-disabled' : 'btn btn-primary  rh-detailed-selected'} onClick={this.sendImagesToReport.bind(this, images)}>
                            {this.props.t('sendToScanReport')}
                            </button>
                        </div>
                      </div>               
                      <div className='row'>
                        <div className='col'>
                            <button data-umami-event='Gallery: Open gallery on mobile' disabled={this.selectCount < 1 && (!this.getWindowDimensions() < 800 || !this.isTouchDevice())} type='button' className={this.selectCount > 0 && (this.getWindowDimensions() < 800 || this.isTouchDevice()) ? 'btn btn-primary rh-detailed-selected' : ' rh-register-button rh-detailed-selected-disabled'} onClick={() => { this.handleOpenClick() }}>
                            {this.props.t('open')}
                            </button> 
                        </div>
                      </div>
              </div>
                
              {/* TODO UMAMI */}
              <div className='row rh-table-wrapper'>
                <div className='col-xl-12 col-12 mb-5 pb-5'>
                  <Gallery data-umami-event='Gallery: click gallery' margin={5} images={this.state.images} enableImageSelection={true} onClick={this.handleClick.bind(this, images)} onSelect={this.handleSelect.bind(this, images)} />
                  {!!currentImage && (
                    <Lightbox
                      data-umami-event='Gallery: Lightbox'
                      mainSrc={currentImage.src}
                      mainSrcThumbnail={currentImage.src}
                      nextSrc={nextImage.src}
                      nextSrcThumbnail={nextImage.src}
                      prevSrc={prevImage.src}
                      prevSrcThumbnail={prevImage.src}
                      onCloseRequest={this.handleClose}
                      onMovePrevRequest={() => this.handleMovePrev(prevIndex)}
                      onMoveNextRequest={() => this.handleMoveNext(nextIndex)}
                    />
                  )}
                </div>
              </div>
            </div>

          </div>
        )}
      </ThemeContext.Consumer>
    )
  }
}

const mapStateToProps = state => ({
  selectRH: state.selectRH,
  fetchedImages: state.images.images,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchImages,
      clearImages,
      dispatch
    },
    dispatch
  )
}

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(withTranslation()(ImageGallery))
