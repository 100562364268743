import {
  SHOW_PROFILE,
  SHOW_APPOINTMENTS,
  SHOW_MESSAGES,
  CLOSE_ALL,
  CLEAR_CSS,
  REMOVE_CLEAR_CSS,
  BURGER_ANIMATION,
} from "./types";

export const showProfile = () => {
  return {
    type: SHOW_PROFILE,
  };
};

export const showAppointments = () => {
  return {
    type: SHOW_APPOINTMENTS,
  };
};

export const showMessages = () => {
  return {
    type: SHOW_MESSAGES,
  };
};

export const closeAll = () => {
  return {
    type: CLOSE_ALL,
  };
};

export const burgerAnimation = () => {
  return {
    type: BURGER_ANIMATION,
  };
};

export const clearCss = () => {
  return {
    type: CLEAR_CSS,
  };
};

export const removeClearCss = () => {
  return {
    type: REMOVE_CLEAR_CSS,
  };
};
