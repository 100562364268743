import { FETCH_EQUIPMENT_START, FETCH_EQUIPMENT_SUCCESS, FETCH_EQUIPMENT_FAILURE } from "../actions/types";

const initialState = {
    equipment: null,
    count: 0,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_EQUIPMENT_START:
            return {
                ...state,
                isLoading: true,
            };
        case FETCH_EQUIPMENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                equipment: action,
                count: action.count
            };
        case FETCH_EQUIPMENT_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.error,
            };
        default:
            return state;
    }
};
