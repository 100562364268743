import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next'


export class Updates extends Component {

    render() {
        return (
            <div>
                <div className="white-background-customers">
                    <div className="table-search-wrapper">
                        <h3 className="header-worklist">{this.props.t('updates')}</h3>
                    </div>
                    <div className="row rh-table-wrapper">
                        <div className="col-xl-12 col-12">
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    security: state.security,
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {

        },
        dispatch
    );
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(withTranslation()(Updates));
