import React, { Component, useRef } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Gallery } from 'react-grid-gallery'
import Lightbox from 'react-image-lightbox'
import { NavLink } from 'react-router-dom'
import {
  fetchScan,
  selectResultHistory,
  fetchScanSpecies,
  getPracticeDataIndex,
  fetchResultHistoryDetails,
} from '../../redux/actions/fetchResultHistoryActions'
import { getPracticeData } from '../../redux/actions/deviceSettingsActions'
import { editScanComment, getScanComment } from "../../redux/actions/scanActions";
import { withTranslation } from 'react-i18next'
import { FaAlignLeft, FaAlignRight, FaCloudDownloadAlt, FaAlignCenter } from "react-icons/fa";
import { ThemeContext } from '../../context/theme-context';
import { USER_ALERT } from '../../redux/actions/types'
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
// import {font } from '../../css/Roboto-Bold-normal'
import '../../css/Roboto-Bold-normal'
import TextareaAutosize from 'react-textarea-autosize';

const emblemEnum = Object.freeze({
  left: 1,
  center: 2,
  right: 3
});

class DetailedReport extends Component {
  constructor() {
    super()
    this.gallery_height = 345
    this.state = {
      commentText: '',
      practiceEmblemState: emblemEnum.left,
      showDetailPreview: false,
      selectedRow: {},
      index: -1,
    }
  }

  async componentDidMount() {

    const { fetchScan, selectRH, fetchedPracticeIndex } = this.props
    await this.props.getPracticeDataIndex()
    await this.props.fetchResultHistoryDetails(selectRH.scan_id)
    await fetchScan(selectRH.scan_id)
    await this.props.getPracticeData()
  }

  async componentDidUpdate(prevProps) {
    const { selectRH, getScanComment } = this.props
    if (prevProps.fetchedScan !== this.props.fetchedScan) {
      await this.props.fetchScanSpecies(this.props.fetchedScan.species.$ref.split('/').at(-1))
      await getScanComment(selectRH.scan_id)
    }
  }

  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };



  onSubmit() {

    let textArea = document.getElementById('textArea');
    let input = textArea.value;
    let comment_instruction_span = document.getElementById('comment_instruction_span');
    let comment_button_text_span = document.getElementById('comment_button_text_span');
    let current_comment_span = document.getElementById('current_comment_span');

    if (input.length > 1000) {
      this.props.dispatch({
        type: USER_ALERT,
        message: this.props.t('tooManyChars'),
        alertType: 'Warning',
      })
      return
    }
    const editRequest = {
      comment: input
    };
    const { selectRH } = this.props
    this.props.editScanComment(editRequest, selectRH.scan_id);

    current_comment_span.innerText = input

    if (comment_instruction_span.innerText == this.props.t('addComment')) {
      comment_instruction_span.innerText = this.props.t('editComment')
    }
    if (comment_button_text_span.innerText == this.props.t('add')) {
      comment_button_text_span.innerText = this.props.t('edit')
    }

  };

  getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return width
  }

  myTextArea = () => {
    const { rhDetails, fetchedScan } = this.props
    if (
      fetchedScan === undefined ||
      fetchedScan == [] ||
      fetchedScan.created === undefined ||
      rhDetails.details.length < 1
    ) {
      return <>
      </>
    } else {
      return (
        <ThemeContext.Consumer>
          {({ theme, darkMode }) => (
            <>
              <TextareaAutosize
                id='textArea'
                name="comment"
                className="report-comments"
                type="text"
                wrap='soft'
                autoComplete='on'
                placeholderTextColor='blue'
                placeholder={fetchedScan.comment ? fetchedScan.comment.length > 0 ? fetchedScan.comment : this.props.t('Characterlimit1000') : this.props.t('Characterlimit1000')}
                title='Insert Additional Comments'
                style={{ backgroundColor: theme.inputcolor, color: theme.textcolor }}
              />
            </>
          )}
        </ThemeContext.Consumer>
      )
    }
  }

  detailColumn = () => {
    const { rhDetails, fetchedScan, selectRH, fetchedScanSpecies, fetchedPracticeIndex, fetchedPracticeLabel } = this.props
    if (
      fetchedScan === undefined ||
      fetchedScan == [] ||
      fetchedScan.created === undefined ||
      fetchedScan.description === undefined ||
      rhDetails.details.length < 1
    ) {
      return <></>
    } else {
      const imageText = fetchedScan.scan_images.length === 1 ? 'image' : 'images'
      return (
        <>
          <div className=''>
            <div>
              <div id='vol_analyzed' className='flex-wrapper-report'>
                <span>{this.props.t('totalVolumeAnalysed')}: </span>{' '}
                <span>
                  <>{rhDetails.details[0].total_volume} ml</>
                  {/* <span className='ml-1'>
                    ({fetchedScan.scan_images.length} {imageText})
                  </span> */}
                </span>
              </div>
            </div>
          </div>

          <div className='col-12 p-0' >
            <table className='dr-table individual-report-table'  >
              <tbody >
                {rhDetails.details.map(detail => (
                  <tr className='table_height' key={detail.description}>
                    <td> {detail.display_name}</td>
                    <td>{detail.eggs_per_gram > 0 ? <strong>{detail.eggs_per_gram}</strong> : detail.eggs_per_gram}</td>
                  </tr>
                ))}
              </tbody>
              <tbody className='dr-table-body'></tbody>
            </table>
          </div>
        </>
      )
    }
  }

  indvReportDetailColumn = () => {

    const { rhDetails, fetchedScan, selectRH, fetchedScanSpecies, fetchedPracticeIndex } = this.props

    if (
      fetchedScan === undefined ||
      fetchedScan == [] ||
      fetchedScan.created === undefined ||
      fetchedScan.description === undefined ||
      rhDetails.details.length < 1
    ) {
      return <></>
    } else {
      const imageText = fetchedScan.scan_images.length === 1 ? 'image' : 'images'

      return (
        <ThemeContext.Consumer>
          {({ theme, darkMode }) => (

            <div className='mt-4 grid-container'>
              <div className='grid-item left-column'>
                <span className='left-col-text'>{this.props.t('scanID')}: </span>
                <span id='br-id'> {selectRH.scan_id}</span>
              </div>
              <div className='grid-item right-column'>
                <span className='left-col-text'>{this.props.t('testDate')}:</span>
                <span id='br-date'>
                  {new Intl.DateTimeFormat('en-GB', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  }).format(fetchedScan.created.$date)}
                </span>
              </div>
              <div className='grid-item left-column'>
                <span className='left-col-text'>{this.props.t('ownerReference')}: </span>
                <span> {fetchedScan.owner_name}</span>
              </div>
              <div className='grid-item right-column'>
                <span className='left-col-text'>{'Practice'}:</span>
                <span> {fetchedPracticeIndex ? fetchedPracticeIndex.id ? fetchedPracticeIndex.id.label : '' : ''}</span>
              </div>
              <div className='grid-item left-column'>
                <span className='left-col-text'>{this.props.t('animalName')}:</span>
                <span> {fetchedScan.animal_id}</span>
              </div>
              <div className='grid-item left-column'>
                <span className='left-col-text'>{this.props.t('species')}: </span>
                <span
                  style={{
                    textTransform: 'capitalize',
                  }}
                >
                  {fetchedScanSpecies}
                </span>
              </div>
              <div className='grid-item left-column'>
                <span className='left-col-text'>{this.props.t('description')}: </span>
                <span className='flex-wrapper-report_text'> {fetchedScan.description}</span>
              </div>
            </div>
          )}
        </ThemeContext.Consumer>
      );


    }
  }

  setEmblemCentre = () => {
    this.setState({ practiceEmblemState: emblemEnum.center })
  }

  setEmblemLeft = () => {
    this.setState({ practiceEmblemState: emblemEnum.left })
  }

  setEmblemRight = () => {
    this.setState({ practiceEmblemState: emblemEnum.right })
  }

  // downloadPD() {
  //   let input = document.getElementById('section-to-print');
  //   html2canvas(input)
  //     .then((canvas) => {
  //       const { images } = this.props.location
  //       let imgData = canvas.toDataURL('image/png');
  //       let pdf = new jsPDF();
  //       pdf.addImage(imgData, 'JPEG', 0, 0);

  //       for(let i=0; i< images.length;i++){ 
  //         pdf.addImage(images[i].src, 'JPEG', 0, 0);
  //       }
  //       // pdf.output('dataurlnewwindow');
  //       pdf.save("download.pdf");
  //     })
  //   ;
  // }


  downloadPDF(images) {
    let down_section = document.getElementById("section-to-print")
    let emblem = document.getElementById("emblem")
    let report_logo_div = document.getElementById("report_logo_div")
    let print_style = document.getElementById("print-style")
    let non_print_section = document.getElementById('no-print')
    let non_print_section_align = document.getElementById('no-print-align')
    let detailed_report_wrapper = document.getElementById('detailed-report-wrapper')
    let parasite_text = document.getElementsByClassName('parasite-text')
    let text_main = document.getElementById('report-header-text_main')
    let text_summary = document.getElementById('report-header-text_summary')
    let text_comments = document.getElementById('report-header-text_comments')
    let table_height = document.getElementsByClassName('table_height')
    let vol_analyzed = document.getElementById('vol_analyzed')
    let scan_id = ''
    let date = ''

    if (document.getElementById('br-id')) {
      scan_id = document.getElementById('br-id').innerText.replace(/\s/g, '');
      date = document.getElementById('br-date').innerText.replace(/\s/g, '');
      let filename = 'Scan-Report_' + scan_id + '_' + date
      non_print_section.setAttribute('hidden', 'true')
      non_print_section_align.setAttribute('hidden', 'true')

      down_section.style.backgroundColor = "#FFFFFF"
      print_style.style.backgroundColor = "#FFFFFF"
      down_section.style.color = "black"
      print_style.style.color = "black"
      down_section.style.width = '793.701px'
      down_section.style.height = '1122.520px'

      if (emblem && report_logo_div) {
        emblem.style.maxWidth = '300px'
        report_logo_div.style.maxWidth = '794px'
      }

      down_section.style.fontSize = '11px'
      text_comments.style.fontSize = '15px'
      text_main.style.fontSize = '15px'
      text_summary.style.fontSize = '13px'
      vol_analyzed.style.fontSize = '11px'
      for (let i = 0; i < table_height.length; i++) {
        table_height[i].style.fontSize = '11px'
        table_height[i].style.height = '22px'
      }
      for (let i = 0; i < parasite_text.length; i++) {
        parasite_text[i].style.fontSize = '10px'
      }

      html2canvas(down_section,
        {
          width: '794',
          height: '1123',
          scale: '1',
        })
        .then((canvas) => {
          let imgData = canvas.toDataURL('image/png', 1.0);
          let pdf = new jsPDF();
          let newPage;
          pdf.setFont('Roboto-Bold')
          pdf.setFontSize(12)
          pdf.addImage(imgData, 'PNG', 0, 0);

          if (images) {
            for (let i = 0; i < images.length; i++) {
              if (i % 2 == 0) {
                newPage = pdf.addPage()
                if (i + 1 === images.length) {
                  newPage.text(`OvaCyte Report Image: ${i + 1}`, 10, 10);
                }
                else {
                  newPage.text(`OvaCyte Report Images: ${i + 1} - ${i + 2}`, 10, 10);
                }
              }
              pdf.addImage(images[i].src, 'JPG', 25, 20 + i % 2 * 140, 160, 120);
            }
          }

          pdf.save(filename);
        });

      non_print_section_align.removeAttribute('hidden')
      non_print_section.removeAttribute('hidden')
      down_section.style.width = null
      down_section.style.height = null
      down_section.style.marginLeft = null
      down_section.style.marginRight = null

      down_section.style.backgroundColor = detailed_report_wrapper.style.backgroundColor
      print_style.style.backgroundColor = detailed_report_wrapper.style.backgroundColor
      down_section.style.color = detailed_report_wrapper.style.color
      print_style.style.color = detailed_report_wrapper.style.color

      if (emblem) { emblem.style.maxWidth = null }
      if (report_logo_div) { report_logo_div.style.maxWidth = null }

      down_section.style.fontSize = null
      text_comments.style.fontSize = null
      text_main.style.fontSize = null
      text_summary.style.fontSize = null
      vol_analyzed.style.fontSize = null
      for (let i = 0; i < table_height.length; i++) {
        table_height[i].style.fontSize = null
        table_height[i].style.height = null
      }
      for (let i = 0; i < parasite_text.length; i++) {
        parasite_text[i].style.fontSize = null
      }

    }

  }

  handleClick = index => this.setState({ index })
  handleClose = () => this.setState({ index: -1 })
  handleMovePrev = prevIndex => this.setState({ index: prevIndex })
  handleMoveNext = nextIndex => this.setState({ index: nextIndex })


  render() {
    const { images } = this.props.location

    var currentImage = null
    var nextIndex = null
    var nextImage = null
    var prevIndex = null
    var prevImage = null

    if (images) {
      let width = this.getWindowDimensions()
      if (width < 500) {
        this.gallery_height = 240
      }
      if (width < 420) {
        this.gallery_height = 160
      }
      currentImage = images[this.state.index]
      nextIndex = (this.state.index + 1) % images.length
      nextImage = images[nextIndex] || currentImage
      prevIndex = (this.state.index + images.length - 1) % images.length
      prevImage = images[prevIndex] || currentImage
    }
    return (
      <>
        <ThemeContext.Consumer>
          {({ theme, darkMode }) => (
            <div className='dr-container' >
              <div className='white-background-customers'>
                <div className='table-search-wrapper'>
                  <h3 style={{ color: theme.textcolor }} className='dr-header'>{this.props.t('individualReport')}</h3>
                </div>
              </div>
              <div className='dr-button-row '>
                <NavLink to='/results-history'>
                  <button data-umami-event='Single Report: Back Button' type='button' className='dr-register-button dr-back'>
                    {this.props.t('back')}
                  </button>
                </NavLink>


                {this.props.rhDetails !== null && this.props.rhDetails.details.length !== 0 && (
                  <div className='container d-flex justify-content-end' style={{ padding: '0px' }}>
                    <div className='row rh-print-buttons justify-content-end'>
                      <div className=''>
                        {/* <FaCloudDownloadAlt className='' size={'100%'} onClick={this.setEmblemRight}></FaCloudDownloadAlt> */}
                        <button data-umami-event='Single Report: Download PDF' onClick={this.downloadPDF.bind(this, images)} type='button' className='dr-register-button dr-print'>
                          {this.props.t('📥	 PDF')}
                        </button>
                      </div>
                      <div className=''>
                        <button data-umami-event='Single Report: Print' onClick={window.print} type='button' className='dr-register-button dr-print'>
                          {this.props.t('print')}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className='detailed-report-wrapper' id='detailed-report-wrapper' style={{ color: theme.textcolor, backgroundColor: theme.background }} >
                <div
                  className='dr-print-wrapper section-to-print-detailed-width'
                  id='section-to-print'
                  style={{ color: theme.textcolor, backgroundColor: theme.background }}
                >
                  <div className='container d-flex justify-content-end noprint' >
                    <div className='row align-buttons noprint' id='no-print-align'>
                      <div className='col d-flex justify-content-center noprint'>
                        <FaAlignLeft data-umami-event='Single Report: Align left' style={{ color: `${this.state.practiceEmblemState === emblemEnum.left ? theme.blue : theme.textcolor}` }} className='align-right noprint' size={'100%'} onClick={this.setEmblemLeft}></FaAlignLeft>
                      </div>
                      <div className='col'>
                        <FaAlignCenter  data-umami-event='Single Report: Align center' style={{ color: `${this.state.practiceEmblemState === emblemEnum.center ? theme.blue : theme.textcolor}` }} className='align-right noprint' size={'100%'} onClick={this.setEmblemCentre}></FaAlignCenter>
                      </div>
                      <div className='col'>
                        <FaAlignRight  data-umami-event='Single Report: Align right' style={{ color: `${this.state.practiceEmblemState === emblemEnum.right ? theme.blue : theme.textcolor}` }} className='align-right noprint' size={'100%'} onClick={this.setEmblemRight}></FaAlignRight>
                      </div>
                    </div>
                  </div>

                  <div className='container' >
                    <div id='print-style' className='mx-auto print-styling' style={{ color: theme.textcolor, backgroundColor: theme.background }}>
                      {this.props.rhDetails !== null && this.props.rhDetails.details.length === 0 ? (
                        <p className='individual-report-title'>{this.props.t('noReportAvailable')}.</p>
                      ) : (
                        <>
                          {/* This is the practice emblem  */}
                          {this.props.device.myPractice && (
                            <div id='report_logo_div' className='pt-4 pb-1 d-flex align-items-end report-logo'>
                              {this.state.practiceEmblemState !== emblemEnum.left ? (
                                <div className='col'></div>
                              ) : (<></>)}
                              <img id='emblem' src={this.props.device.myPractice} alt='' />
                              {this.state.practiceEmblemState === emblemEnum.center ? (
                                <div className='col'></div>
                              ) : (<></>)}
                            </div>
                          )}
                        </>

                      )}



                      {this.indvReportDetailColumn()}

                      <div className='table-search-wrapper'>
                        <h4 id='report-header-text_main' className='report-header-text'>{this.props.t('individualReport')}</h4>
                      </div>
                      {/* This is the line */}
                      <h4
                        className='mb-4'
                        style={{
                          paddingTop: '5px',
                          paddingBottom: '15px',
                          borderBottom: '1px solid #4472C4',
                          fontSize: '20px',
                        }}
                      >
                      </h4>

                      <div >
                        <div className='table-search-wrapper'>
                          <h4 id='report-header-text_summary' style={{ fontSize: "24px" }} className='report-header-text-left'>{this.props.t('summary')}</h4>
                        </div>

                        {this.detailColumn()}

                        {this.props.rhDetails !== null && this.props.rhDetails.details.length !== 0 && (
                          <div>
                            <br />
                            {this.props.rhDetails.details.map(detail => {
                              const parasiteName = detail.description[0].toUpperCase() + detail.description.slice(1)
                              return (
                                detail.buckets !== '' && (
                                  <div className='mb-3 parasite-text' id='parasite-tex' key={detail.description}>
                                    <p className='mb-1'>
                                      {detail.description === 'anoplocephala'
                                        ? detail.display_name
                                        : parasiteName.replaceAll('_', ' ')}
                                    </p>
                                    <div className='api-text ml-5'>{detail.buckets}</div>
                                  </div>
                                )
                              )
                            })}

                          </div>

                        )}
                      </div>
                      {/* <Gallery className='' images={images} margin={6} onClick={this.handleClick} rowHeight={this.gallery_height} enableImageSelection={false}/>
                      {!!currentImage && (
                          <Lightbox
                            mainSrc={currentImage.src}
                            mainSrcThumbnail={currentImage.src}
                            nextSrc={images.indexOf(currentImage) < images.length -1 ?  nextImage.src : ''}
                            nextSrcThumbnail={images.indexOf(currentImage) < images.length -1 ?  nextImage.src : ''}
                            prevSrc={images.indexOf(currentImage) > 0 ? prevImage.src : ''}
                            prevSrcThumbnail={images.indexOf(currentImage) > 0 ?  prevImage.src : ''}
                            onCloseRequest={this.handleClose}
                            onMovePrevRequest={() => this.handleMovePrev(prevIndex)}
                            onMoveNextRequest={() => this.handleMoveNext(nextIndex)}
                            imageTitle={images.indexOf(currentImage)}
                            imageCaption={currentImage.src}
                            imagePadding={30}
                          />
                      )} */}

                      <div className='table-search-wrapper'>
                        <h4 id='report-header-text_comments' className='report-header-text'>{this.props.t('comments')}</h4>
                      </div>
                      <h4
                        className='mb-4'
                        style={{
                          paddingTop: '5px',
                          paddingBottom: '15px',
                          borderBottom: '1px solid #4472C4',
                          fontSize: '20px',
                        }}
                      >
                      </h4>

                      <div className='flex-wrapper-report_comment'>
                        <div className=''><b>{this.props.t('currentScanComment')}: </b></div>
                        <br></br>
                        <span className='current_comment_span' id='current_comment_span'> {this.props.fetchedScan.comment}</span>
                      </div>
                    </div>

                    <div id='no-print'  >
                      <div data-html2canvas-ignore className="noprint scan-inputs-properties">
                        <span id='comment_instruction_span' >{this.props.fetchedScan.comment ? (this.props.t('editComment')) : this.props.t('addComment')}</span>
                      </div>
                      <div className='mr-3 noprint'>
                        {this.myTextArea()}
                        <div className='noprint'>
                          <button data-umami-event='Single Report: Edit or Add report comment' id='submitButton' onClick={this.onSubmit.bind(this)} type='button' className='dr-submit-button'>
                            <span id='comment_button_text_span' > {this.props.fetchedScan.comment ? (this.props.t('edit')) : this.props.t('add')}</span>
                          </button>
                          { }
                        </div>
                        <br></br>

                      </div>

                    </div>

                    <div>
                      {images ?
                        <div>
                          <div data-html2canvas-ignore className='noprint table-search-wrapper'>
                            <h4 className='noprint report-header-text mr-3'>{this.props.t('images')}</h4>
                          </div>

                          <h4
                            data-html2canvas-ignore
                            className='mb-4 mr-3 noprint'
                            style={{
                              paddingTop: '5px',
                              paddingBottom: '15px',
                              borderBottom: '1px solid #4472C4',
                              fontSize: '20px',
                            }}
                          >
                          </h4>
                        </div> : ''
                      }
                      <div className='mr-3'>
                        {images ? images.map((images, index) => (<div data-html2canvas-ignore ><br></br><img className='p-1' width="100%" height="100%" src={images.src} key={index} alt="Alt tag as in missing image" /></div>)) : ''}
                      </div>
                    </div>
                    <br></br>
                  </div>
                </div>


              </div>
            </div>
          )}
        </ThemeContext.Consumer>
      </>
    )
  }
}

const mapStateToProps = state => ({
  security: state.security,
  selectRH: state.selectRH,
  rhDetails: state.rhDetails,
  fetchComment: state.getScanComment,
  fetchedScan: state.scan.scan,
  fetchedScanSpecies: state.species?.scan?.label?.replaceAll('_', ' '),
  fetchedPracticeIndex: state.getPracticeDataIndex,
  fetchedImages: state.images.images,
  device: state.device,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchScan,
      fetchResultHistoryDetails,
      selectResultHistory,
      fetchScanSpecies,
      getPracticeDataIndex,
      getPracticeData,
      editScanComment,
      getScanComment,
      dispatch,
    },
    dispatch
  )
}


export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(DetailedReport))
