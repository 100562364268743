import {FETCH_IMAGES, CLEAR_IMAGES} from "../actions/types";

const initialState = {
  images: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_IMAGES:
      return {
        ...state,
        images: action.payload,
      };
    case CLEAR_IMAGES:
      return {
        ...state,
        images: [],
      };
    default:
      return state;
  }
};
