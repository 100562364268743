import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

import Login from "./containers/Login";
import Signup from "./containers/Signup";
import ForgotPassword from "./containers/ForgotPassword";
import VerifyEmail from "./containers/VerifyEmail";

// Admin Routes
import AdminDashboard from "./containers/Admin/Dashboard";
import ResultsHistory from "./containers/Admin/ResultsHistory";
import Equipment from "./containers/Admin/Equipment";
import DetailedReport from "./containers/Admin/DetailedReport";
import BatchReport from "./containers/Admin/BatchReport";
import Contact from "./containers/Admin/Contact";
import About from "./containers/Admin/About";
import Support from "./containers/Admin/Support/Support";
import ImageGallery from "./containers/Admin/ImageGallery";
import FAQ from "./containers/Admin/FAQ";
import Updates from "./containers/Admin/Updates";

// User Routes
import CreateScan from "./containers/User/CreateScan";
import Worklist from "./containers/Admin/Worklist";
import FecScanHelp from "./containers/Admin/Support/FecScanHelp";
import CaScanHelp from "./containers/Admin/Support/CaScanHelp";
import FlukeScanHelp from "./containers/Admin/Support/FlukeScanHelp";

class Routes extends Component {
  render() {
    let exposedUrls;
    if (this.props.security.validToken) {
      let role = "ROLE_USER";

      switch (role) {
        // Admin private routes
        case "ROLE_USER":
          exposedUrls = (
            <Switch>
              <Route exact path="/" component={AdminDashboard} />
              <Route exact path="/scan" key="create-scan" component={CreateScan} />
              <Route exact path="/edit-scan" key="edit-scan" component={CreateScan} />
              <Route exact path="/pending-scans" component={Worklist} />
              <Route exact path="/detailed-report" component={DetailedReport} />
              <Route exact path="/batch-report" component={BatchReport} />
              <Route exact path="/image-gallery" component={ImageGallery} />
              <Route exact path="/results-history" component={ResultsHistory}/>
              <Route exact path="/equipment" component={Equipment} />
              <Route exact path="/faqs" component={FAQ} />
              <Route exact path="/updates" component={Updates} />
              <Route exact path="/about" component={About} />
              <Route exact path="/support" component={Support} />
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/fluke-scan-help" component={FlukeScanHelp} />
              <Route exact path="/fec-scan-help" component={FecScanHelp} />
              <Route exact path="/pet-scan-help" component={CaScanHelp} />
            </Switch>
          );
          break;
        default:
          break;
      }
    } else {
      exposedUrls = (
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/Signup" component={Signup} />
          <Route exact path="/ForgotPassword" component={ForgotPassword} />
          <Route exact path="/VerifyEmail" component={VerifyEmail} />
        </Switch>
      );
    }

    return (
      <div className="flex-div-wrapper" id="routes-container">
        <Switch>
          {
            // Public Routes
          }
          {exposedUrls}
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  security: state.security,
});

export default connect(mapStateToProps, null)(Routes);
