import { PENDING_SCANS_TABLE_PAGE, RESULTS_HISTORY_TABLE_PAGE, EQUIPMENT_TABLE_PAGE } from "./types";

export const pendingScansPageHandler = page => {
  return dispatch => {
    dispatch({ type: PENDING_SCANS_TABLE_PAGE, payload: page });
  };
};

export const resultsHistoryPageHandler = page => {
  return dispatch => {
    dispatch({ type: RESULTS_HISTORY_TABLE_PAGE, payload: page });
  };
};

export const equipmentPageHandler = page => {
  return dispatch => {
    dispatch({ type: EQUIPMENT_TABLE_PAGE, payload: page });
  };
};

