import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { withTranslation } from 'react-i18next'

class ErrorModal extends Component {
  message = "";
  status = "";
  render() {
    let errorStatusSwitcher = () => {
      switch (this.props.status) {
        case "BAD_REQUEST":
          return this.props.t('badRequest');
        case "401 UNAUTHORIZED":
          this.status = this.props.t('loginFailed');
          this.message = this.props.t('usernameOrPasswordIsIncorrectPleaseTryAgain');
          break;
        case "403 FORBIDDEN":
          return this.props.t('noAccessAllowed');
        case "404 NOT_FOUND":
          return this.props.t('theResourceDoesNotExist');
        case "408 REQUEST_TIMEOUT":
          return this.props.t('connectionTimeout');
        default:
          return "";
      }
    };

    return (
      <div>
        {this.props.show === true ? (
          <Modal id="errorModal" show={this.props.show} onHide={this.props.handleClose}>
            <Modal.Header closeButton id="errorModalHeader">
              <Modal.Title id="errorModalTitle">
                {errorStatusSwitcher()}
                {this.status}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body id="errorModalBody">{this.message}</Modal.Body>
            <Modal.Footer id="errorModalFooter">
              <Button className="btn-danger" onClick={this.props.handleClose}>
                {this.props.t('close')}
              </Button>
            </Modal.Footer>
          </Modal>
        ) : (
          <div></div>
        )}
      </div>
    );
  }
}

export default withTranslation()(ErrorModal);
